// ##### Search2 Component ##### //

.c-search2 {
  display: flex;
  position: fixed; // prevents horizontal overflow and scrollbar on small screens
  right: 0;
  left: 0;
  align-items: flex-start;

  @include bp(screen2) {
    position: absolute;
    left: auto;
    width: 22em;
  }

  // Reset default fieldset styles; hide legend:
  fieldset {
    @extend %u-reset-fieldset-styles;

    legend {
      @extend %u-hide;
    }

  }

}

.c-search2__inputs {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  border: 1px solid $color-light-gray;
  border-radius: $round-corner;
}

.c-search2__form {
  display: flex;
  background-color: $color-white;
  z-index: 1;
}

.c-search2__label {
  @extend %u-hide;
}

.c-search2__field {
  @extend %u-interactive-element-size;
  flex: 1 0 auto;
  padding: 0 0 0 30px;
  border: none;
  background: inline('../images/icon_magnify.svg') no-repeat left 8px center / 16px;

  @include bp(screen3) {
    padding: 0 0 0 $spacing-sm;
    background: none;
  }

}

.c-search2__submit-button {
  @extend %u-hide;
  @extend %o-button__9;
  background-image: inline('../images/icon_magnify.svg');

  @include bp(screen3) {
    position: static;
    left: auto;
  }

}

.c-search2__search-close-button {
  @extend %o-button__9;
  background-color: $color-white;
  background-image: inline('../images/icon_close.svg');

  @include bp(screen3) {
    display: none;
  }

}

%c-search2__refine {
  @extend %u-interactive-element-size;
  display: flex;
  align-items: center;
  padding: 0 8px;
  transition: min-height 0.5s, margin-top 0.5s;
  transition-delay: 0.2s; // fixes open/close jitter upon onfocus/blur
  background: lighten($color-light-gray, 10%);

  label {
    padding-top: 8px;
    padding-bottom: 6px;
    font-size: 0.9em;

    &:not(:last-of-type) {
      margin-right: 15px;
    }

  }

  // ***** Custom Checkbox Styles ***** //

  [type='radio'] {
    // hide default radio button:
    @extend %u-hide;

    // unchecked style:
    + label:before {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      transition: border 0.2s, background-color 0.2s;
      border: 1px solid $color-black;
      border-radius: 50%;
      content: '';
      vertical-align: middle;
    }

    // checked style:
    &:checked + label:before {
      background-color: $color-teal;
    }

    // focused style:
    &:focus + label:before {
      border: 2px solid $color-black;
      background-color: desaturate(lighten($color-teal, 20%), 20%);
    }

  }

}

.c-search2__refine {
  @extend %c-search2__refine;
  margin-top: (-$pointer-size-fine);

  @include u-interactive-element-pointer(coarse) {
    margin-top: (-$pointer-size-coarse);
  }

}

.c-search2__refine--active {
  @extend %c-search2__refine;
  margin-top: 0;
}
