// ##### Media List Component ##### //

$medialist-item-length: 11em;

.c-medialist {
  @extend %u-reset-list-styles;
  @extend %u-clearfix;
  flex: 1 0 auto; // to c-scholworks__media flexbox
  margin-right: $spacing-md;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($medialist-item-length, 1fr));
    grid-column-gap: $spacing-md;
  }

}

%c-medialist__item {
  width: $medialist-item-length;
  margin-right: $spacing-md;
  margin-bottom: $spacing-sm;
  padding-left: 1.5em;
  float: left;
  background: no-repeat left top / 16px;

  @supports (display: grid) {
    width: auto;
    margin-right: 0;
    float: none;
  }

}

.c-medialist__audio {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_volume-up-black.svg');
}

.c-medialist__image {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_picture-black.svg');
}

.c-medialist__other {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_file-black.svg');
}

.c-medialist__pdf {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_custom-pdf-black.svg');
}

.c-medialist__video {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_video-camera-black.svg');
}

.c-medialist__zip {
  @extend %c-medialist__item;
  background-image: inline('../images/icon_archive-black.svg');
}
