// ##### Custom Fonts ##### //

@font-face {
  font-family: 'SourceSansPro';
  src:
    url('../fonts/SourceSansPro-Regular.ttf.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Regular.ttf.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansProLight';
  src:
    url('../fonts/SourceSansPro-Light.ttf.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Light.ttf.woff') format('woff');
  font-display: swap;
}
