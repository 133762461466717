// ##### Filter Component ##### //

%c-filter {
  @extend %u-clearfix;
  margin-bottom: $spacing-md;
  padding: $spacing-sm;
  background: $color-white;
  box-shadow: $box-shadow1;
}

.c-filter {
  @extend %c-filter;
}

.c-filter--active {
  @extend %c-filter;

  .c-filter__results {
    margin-bottom: $spacing-sm;
  }

  .c-filter__inactive-note {
    display: none;
  }

  .c-filter__active {
    display: block;
  }

}

.c-filter__heading {
  @extend %o-heading3a;
}

.c-filter__results {
  margin-bottom: $spacing-md;
}

.c-filter__inactive-note {
  margin-bottom: $spacing-sm;
}

.c-filter__active {
  display: none; // gets set to 'block' with c-filter--active declaration above

  summary {
    margin-right: $spacing-md;
    float: left;
    user-select: none;

    span {
      @extend %u-interactive-element-size;
      display: flex;
      align-items: center;
      color: $color-teal;

      &::before {
        content: 'Show ';
      }

      strong {
        margin: 0 0.3em; // reinstate collapsed flexbox whitespace
        color: $color-dark-orange;
      }

    }

  }

}

.c-filter__active[open] {
  
  summary {

    span {

      &::before {
        content: 'Hide ';
      }

    }

  }

}

.c-filter__active-list {
  @extend %u-reset-list-styles;
  clear: left;

  button {
    @extend %o-button__5;
    background-image: inline('../images/icon_times-circle.svg');
    color: $color-black;
    font-weight: normal;
    text-align: left;

    &:focus,
    &:hover {
      background-image: inline('../images/icon_times-circle-orange.svg');
    }

  }

}

.c-filter__clear-all {
  @extend %u-interactive-element-size;
  padding: 0 1.3em 0 0;
  float: right;
  border: none;
  background: $color-white inline('../images/icon_times.svg') no-repeat right -0.2em center / 1.3em;
  text-align: right;
}

.c-filter__tips {
  @extend %o-textlink__secondary;
  display: block;
  padding-top: $spacing-sm;
  clear: left;
}
