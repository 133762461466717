// ##### Description List ##### //

.c-descriptionlist {
  line-height: $line-height1;

  dt {
    font-weight: bold;

    a {
      @extend %o-textlink__secondary;
    }

  }

  dd {
    margin-bottom: $spacing-md;
    margin-left: 0;
  }

}
