// ##### Related Items Component ##### //

.c-relateditems {
  @extend %u-reset-list-styles;

  li {
    line-height: $line-height1;

    @include u-heading() {
      margin: 0;
      font-size: 1em;
      font-weight: normal;
    }

    &:not(:last-child) {
      margin-bottom: $spacing-sm;
    }

  }

  // If authorlist component is used:
  .c-authorlist {
    margin-bottom: 0;

    li {
      margin-bottom: 0;
    }

  }

}
