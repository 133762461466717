// ##### Export/RSS Component ##### //

.c-export {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-sm;
}

.c-export__export-button {
  @extend %o-button__5;
  background-image: inline('../images/icon_share-square.svg');
}

.c-export__rss-button {
  @extend %o-button__5;
  background-image: inline('../images/icon_rss-square.svg');
}
