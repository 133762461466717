// ##### Custom Selector Objects ##### //

%o-customselector {
  display: inline-flex;
  align-items: center;
  margin-bottom: $spacing-md;

  @include bp(screen3) {
    position: relative;
  }

}

// Normal selector:
.o-customselector {
  @extend %o-customselector;
}

%o-customselector__heading {
  margin: 0 $spacing-base 0 0;
  font-weight: normal;
}

// Normal selector heading:
.o-customselector__heading {
  @extend %o-customselector__heading;
  font-size: 1.5em;
}

.o-customselector__selector {
  @extend %u-interactive-element-size;

  summary {
    @extend %u-interactive-element-size;
    position: relative;

    @include bp(screen3) {
      position: absolute;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inline('../images/icon_arrow-down.svg') no-repeat center / 0.8em;
      content: '';
    }

  }

  &[open] {

    summary::after {
      transform: rotate(180deg);
    }

  }

}

.o-customselector__menu {
  position: absolute; // to o-customselector at base screen or .o-customselector__selector at screen3
  right: $spacing-sm;
  left: $spacing-sm;
  margin-top: $spacing-base;
  background-color: $color-white;
  z-index: 2;

  @include bp(screen3) {
    top: 100%;
    right: 0;
    left: 0;
    min-width: 10em;
  }

}

.o-customselector__sub-heading {
  @extend %nav-item;
  border-bottom: none;
  background-color: $color-light-gray;
  color: $color-black;
}

.o-customselector__items {
  @extend %u-reset-list-styles;

  li {

    &:not(:first-child) {

      a {
        border-top: none;
      }

    }

    a {
      @extend %nav-item;
    }

  }

}
