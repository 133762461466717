// ##### Deposit Wizard Component ##### //

$wizard-nav-icon-size: 1.5em;

// ***** Animation ***** //

.c-wizard {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  .c-wizard__current-bkw,
  .c-wizard__standby-bkw,
  .c-wizard__current-fwd,
  .c-wizard__standby-fwd {
    position: absolute; // allow stacking
  }

}

// Forward:

.c-wizard__current-fwd {
  z-index: 5;
  animation: current-fwd 1s;
}

.c-wizard__standby-fwd {
  @extend %u-hide;
  width: 400px;
  z-index: 1;
  animation: standby-fwd 1s;
}

@keyframes current-fwd {

  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }

}

@keyframes standby-fwd {

  0% {
    left: 0;
    opacity: 1;
  }

  100% {
    left: -400px;
    opacity: 0;
  }

}

// Backward:

.c-wizard__current-bkw {
  z-index: 5;
  animation: current-bkw 1s;
}

.c-wizard__standby-bkw {
  @extend %u-hide;
  width: 400px;
  z-index: 1;
  animation: standby-bkw 1s;
}

@keyframes current-bkw {

  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }

}

@keyframes standby-bkw {

  0% {
    left: 0;
    opacity: 1;
  }

  100% {
    left: 400px;
    opacity: 0;
  }

}

// ***** General Styling ***** //

.c-wizard__step {
  padding: $spacing-md;
  border-radius: $round-corner;
  background-color: $color-light-gray;
  box-shadow: 5px 5px 15px $color-light-black;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: (-$spacing-md) (-$spacing-md) $spacing-md;
    padding-right: $spacing-sm;
    padding-left: $spacing-md;
    border-top-left-radius: $round-corner;
    border-top-right-radius: $round-corner;
    background-color: $color-light-black;
    overflow-x: hidden; // prevents <a> and <button> %u-hide horizontal scrolling

    h1 {
      order: 2;
      margin: 0;
      outline: none; // remove focus outline
      color: $color-white;
      font-size: 1em;
      font-weight: normal;
    }

    a {
      @extend %u-interactive-element-size;
      order: 1;
      margin-left: (-$spacing-sm);
      transform: scaleX(-1);
      background: inline('../images/icon_caret-right-white.svg') no-repeat center / #{$wizard-nav-icon-size};

      span {
        @extend %u-hide;
      }

    }

    button {
      @extend %o-button__9;
      order: 3;
      background-image: inline('../images/icon_close-white.svg');
      background-size: 0.9em;

      span {
        @extend %u-hide;
      }

    }

  }

  footer {
    font-size: 0.9em;

    a {
      @extend %o-textlink__secondary;
    }

  }

}

.c-wizard__heading {
  margin-bottom: $spacing-md;
  font-size: 1.1em;
}

.c-wizard__list {
  @extend %u-reset-list-styles;
  margin-bottom: 0;

  li {
    margin-bottom: $spacing-sm;

    a {
      @extend %u-interactive-element-size;
      display: flex;
      align-items: center;
      padding: $spacing-base 2.5em $spacing-base $spacing-sm;
      background: $color-teal inline('../images/icon_caret-right-white.svg') no-repeat center right #{$spacing-sm} / #{$wizard-nav-icon-size};
      color: $color-white;
      text-decoration: none;
    }

  }

}

.c-wizard__list-black {
  @extend %u-reset-list-styles;
  margin-bottom: $spacing-md;

  li {
    margin-bottom: $spacing-sm;

    a {
      @extend %u-interactive-element-size;
      display: flex;
      align-items: center;
      padding: $spacing-base 2.5em $spacing-base 35px;
      background: $color-light-black inline('../images/icon_reload-white.svg') no-repeat left $spacing-sm center / 16px;
      color: $color-white;
      text-decoration: none;
    }
  }
}

.c-wizard__message {
  margin-bottom: $spacing-md;

  a {
    @extend %o-textlink__secondary;
  }

  button {
    @extend %o-button__8;
    display: inline-block;
    margin: 0 auto $spacing-sm;
  }

}

.c-wizard__centered {
  display: flex;
  justify-content: center;
}

.c-wizard__external-link {
  @extend %o-textlink__right-icon;
}

// ***** Modal ***** //

.c-wizard__modal {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin-top: $spacing-md;
  outline: none !important; // remove default modal focus outline
  overflow-y: auto;
  overflow-x: hidden;
}

.c-wiz-modal__portal {

  // sass-lint:disable class-name-format
  .ReactModal__Overlay {
      transition: opacity 500ms ease-in-out;
      opacity: 0;
  }

  .ReactModal__Overlay--after-open {
      opacity: 1;
  }

  .ReactModal__Overlay--before-close {
      opacity: 0;
  }
}
