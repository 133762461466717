// ##### Hero Component ##### //

$hero-bg-color: rgba($color-teal, .7);
$hero-text-shadow: 1px 1px 1px $color-light-black;

.c-hero {
  position: relative;
  padding: $spacing-sm;
  background-color: $color-teal;
  
  // This prevents the inline background-image style referenced in the HTML from appearing up to the screen3 breakpoint size:
  @media (max-width: $screen3 - 1) {
    background-image: none !important;
  }

  @include bp(screen3) {
    display: flex;
    align-items: center;
    height: 30vw;
    padding: 0;
    background: $color-teal no-repeat center / cover;
    // background-image property/URL is set as an inline HTML style on this element
    z-index: 0;
  }

  @include bp(screen4) {
    height: 24em;
  }

  @include u-heading() {
    @extend %u-sourcesansprolight-font-stack;
    margin: 0;
    color: $color-white;
    font-size: 1.3em;
    font-weight: normal;
    text-align: center;

    @include bp(screen3) {
      width: 11em;
      padding: $spacing-md $spacing-lg;
      background: linear-gradient(to right, $hero-bg-color 85%, transparent);
      font-size: 4vw;
      text-align: left;
      text-shadow: $hero-text-shadow;
    }

    @include bp(screen4) {
      font-size: 3.2em;
    }

  }

}

%c-hero__campus-text {
  color: $color-white;
  text-shadow: $hero-text-shadow;
}

.c-hero__campus {
  display: none;

  @include bp(screen3) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: $spacing-md $spacing-md $spacing-md $spacing-lg;
    background-image: linear-gradient(to left, $hero-bg-color 80%, transparent);
  }

  span {
    @extend %c-hero__campus-text;
    display: block;
    margin-bottom: $spacing-sm;
    font-size: 1.6em;
  }

  a {
    @extend %c-hero__campus-text;
    @extend %o-textlink__right-arrow;
    background-image: inline('../images/icon_angle-right-white.svg');
    font-size: 1.2em;
  }

}
