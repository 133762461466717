// ##### Columns Layout Component ##### //

%c-columns {

  @include bp(screen2) {
    display: flex;
    align-items: flex-start;
  }

  main {
    flex: 1;
  }

  aside {
    flex: 0 0 $column-width;

    &:first-child { // left sidebar

      @include bp(screen2) {
        margin-right: $spacing-md;
      }

    }

    &:last-child { // right sidebar

      @include bp(screen2) {
        margin-left: $spacing-md;
      }

    }

  }

  &.is-loading-data {

    &::after {
      position: fixed; /* see https://stackoverflow.com/questions/2852276/make-div-overlay-entire-page-not-just-viewport */
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color-black-semitrans;
      content: '';
      z-index: 99;

    }

  }

}

.c-columns {
  @extend %c-columns;
}

// If the main colum is longer than the sidebar, then fix it in-place but not beyond the top of the viewport:

.c-columns--sticky-sidebar {
  @extend %c-columns;

  aside {
    position: sticky;
    top: $spacing-md;
    overflow: scroll; // enable vertical scrolling...
    max-height: 95vh; // ...if sidebar gets taller than 95% of the viewport height
  }

}

