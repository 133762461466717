// ##### PDF View Component ##### //

.c-pdfview {
  
  @include bp(screen1) {
    position: absolute;
    top: $spacing-md;
    right: 0;
  }

}

.c-pdfview__button-download {
  @extend %o-button__6;
  // fixes chrome/safari min-height + padding within <details> bug:
  padding-top: 0;
  padding-bottom: 0;
  background-image: inline('../images/icon_download-white.svg');

  @include bp(screen1) {
    display: none;
  }

}

.c-pdfview__button-view {
  @extend %o-button__6;
  display: none;

  @include bp(screen1) {
    display: inline-block;
    // fixes chrome/safari min-height + padding within <details> bug:
    padding-top: 0;
    padding-bottom: 0;
    background-image: inline('../images/icon_expand-white.svg');
  }

}

.c-pdfview__accessibility {
  display: none;

  a {
    @extend %o-textlink__secondary;
  }

  @include bp(screen1) {
    display: block;
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
  }
}

.c-pdfview__viewer {
  display: none;

  @include bp(screen1) {
    display: block;
  }

}

#pdfjs-cdl-wrapper #errorWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ccc;
  color: #000;
  font-size: 1.5em;
}

#errorShowMore, #showErrorLess {
  display: none;
}

#pdfjs-cdl-wrapper #errorMessageLeft {
  padding: 0.5em;
}
