// ##### Button Objects ##### //

%o-button {
  @extend %u-interactive-element-size;
  padding: $spacing-base $spacing-sm;
  border: none;
  border-radius: $round-corner;
  background: none;
}

// no border or background with teal text:
%o-button__1 {
  @extend %o-button;
  color: $color-teal;
}

.o-button__1 {
  @extend %o-button__1;
}

// thin border, no background:
%o-button__2 {
  @extend %o-button;
  border: 1px solid $color-dark-gray;
  box-shadow: $box-shadow2;
}

.o-button__2 {
  @extend %o-button__2;
}

// orange background, white text:
%o-button__3 {
  @extend %o-button;
  background: $color-dark-orange;
  color: $color-white;
  box-shadow: $box-shadow2;
}

.o-button__3 {
  @extend %o-button__3;
}

// no border or background with orange text:
%o-button__4 {
  @extend %o-button;
  color: $color-dark-orange;
}

.o-button__4 {
  @extend %o-button__4;
}

// ***** Buttons with Icons ***** //

// Each version below uses a default icon. To use alternative icons, use background-image property with path to alternative image

// no border or background, bold teal text and left icon:
%o-button__5 {
  @extend %o-button;
  padding-left: 30px;
  background: no-repeat left 8px center / 16px;
  color: $color-teal;
  font-weight: bold;
}

.o-button__5 {
  @extend %o-button__5;
  // Default icon below. To use a different icon: Create a new class, @extend with %o-button__5, and apply background-image property with alternative image path
  background-image: inline('../images/icon_share-square.svg');
}

// no border, teal background, bold white text and left icon:
%o-button__6 {
  @extend %o-button;
  padding-left: 30px;
  background: $color-teal no-repeat left 8px center / 16px;
  color: $color-white;
  box-shadow: $box-shadow2;
}

.o-button__6 {
  @extend %o-button__6;
  // Default icon below. To use a different icon: Create a new class, @extend with %o-button__6, and apply background-image property with alternative image path
  background-image: inline('../images/icon_print-white.svg');
}

// no min-height, padding, or background with teal text:
%o-button__7 {
  @extend %o-button;
  min-height: 0;
  padding: 0;
  color: $color-teal;
}

.o-button__7 {
  @extend %o-button__7;
}

// teal background, white text:
%o-button__8 {
  @extend %o-button;
  background: $color-teal;
  color: $color-white;
  box-shadow: $box-shadow2;
}

.o-button__8 {
  @extend %o-button__8;
}

// icon button:
%o-button__9 {
  @extend %o-button;
  background: no-repeat center / 70%;

  &::before {
    content: '\00a0'; // no-break space
  }

}

.o-button__9 {
  @extend %o-button__9;
  background-image: inline('../images/icon_magnify.svg');
}
