// ##### Lazy Image Component ##### //

.c-lazyimage {
  transition: opacity, 1s;
  opacity: 0;

  &[data-loaded='true'] {
    opacity: 1;
  }

}
