// ##### Skip Navigation Component ##### //

.c-skipnav {
  @extend %u-hide;
  display: inline-block;
  padding: $spacing-sm;
  transition: opacity 0.5s;
  border-radius: $round-corner;
  background-color: $color-teal;
  color: $color-white;
  text-decoration: none;
  opacity: 0;
  z-index: 1;

  &:focus {
    top: $spacing-sm;
    left: $spacing-sm;
    opacity: 1;
  }

}
