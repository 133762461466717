// ##### Scholarly Works Component ##### //

$scholworks-tag-height: 1.4em;

.c-scholworks {
  display: flex;
  flex-flow: column nowrap;

  @include bp(screen1) {
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  ~ .c-scholworks {
    padding-top: $spacing-md;
    border-top: 1px solid $color-dark-gray;
  }

  &:not(:last-child) {
    margin-bottom: $spacing-sm;
  }

}

.c-scholworks__main-column {
  flex: 1 1 auto;
}

.c-scholworks__tag-list {
  @extend %u-reset-list-styles;
}

%c-scholworks__tag {
  display: inline-flex;
  align-items: center;
  min-height: $scholworks-tag-height;
  margin: 0 $spacing-sm $spacing-md 0;
  padding: 0 $spacing-sm 0 $spacing-lg;
  border-radius: $round-corner;
  background: $color-light-gray no-repeat left $spacing-sm center / 1em;
  text-transform: capitalize;
}

.c-scholworks__tag-article {
  @extend %c-scholworks__tag;
  background-image: inline('../images/icon_file-text-black.svg');
}

.c-scholworks__tag-book {
  @extend %c-scholworks__tag;
  background-image: inline('../images/icon_book.svg');
}

.c-scholworks__tag-thesis {
  @extend %c-scholworks__tag;
  background-image: inline('../images/icon_graduation-cap-black.svg');
}

.c-scholworks__tag-multimedia {
  @extend %c-scholworks__tag;
  background-image: inline('../images/icon_play-circle-o-black.svg');
}

.c-scholworks__tag-peer {
  @extend %c-scholworks__tag;
  padding: 0 $spacing-sm;
}

.c-scholworks__heading {
  @extend %o-heading1a;
  max-height: 3.2em; // truncate beyond 3 lines per jquery.dotdotdot
  overflow: hidden; // hide text beyond max-height

  @include bp(screen1) {
    max-height: 2.2em; // truncate beyond 3 lines per jquery.dotdotdot
  }

  a {
    @extend %o-textlink__secondary;
  }

}

.c-scholworks__publication {
  margin-bottom: $spacing-md;

  a {
    @extend %o-textlink__secondary;
  }

}

.c-scholworks__abstract {
  max-height: 3.8em; // truncate beyond 3 lines per jquery.dotdotdot
  margin: 0 0 $spacing-md;
  overflow: hidden; // hide text beyond max-height

  @include bp(screen1) {
    max-height: 2.7em; // truncate beyond 2 lines per jquery.dotdotdot
  }

  p {
    line-height: $line-height2;
  }

}

.c-scholworks__media {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  img {
    margin-bottom: $spacing-sm;
    margin-left: auto;
  }

}

.c-scholworks__ancillary {
  flex: 0 0 auto;
  align-self: flex-start;
  width: 9.4em;
}

.c-scholworks__thumbnail {
  display: none;

  @include bp(screen1) {
    display: block;
    margin-top: calc(#{$scholworks-tag-height} + #{$spacing-md});
    margin-bottom: $spacing-sm;
    margin-left: $spacing-md;
    border: 1px solid $color-light-gray;
    box-shadow: $box-shadow2;
  }

  img {
    display: block;
    width: 128px;
    height: auto;
  }

}

.c-scholworks__license {
  display: block;

  @include bp(screen1) {
    margin-left: $spacing-md;    
  }
}
