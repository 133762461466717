// ##### Heading Objects ##### //

%o-heading {
  margin-top: 0;
  margin-bottom: $spacing-sm;
}

%o-heading1 {
  @extend %o-heading;
  color: $color-teal;
  font-size: 1.6em;
  font-weight: normal;
}

.o-heading1 {
  @extend %o-heading1;
}

%o-heading1a {
  @extend %o-heading;
  font-size: 1.6em;
  font-weight: normal;
}

.o-heading1a {
  @extend %o-heading1a;
}

%o-heading2 {
  @extend %o-heading;
  color: $color-teal;
  font-size: 1.3em;
  font-weight: normal;
}

.o-heading2 {
  @extend %o-heading2;
}

%o-heading2a {
  @extend %o-heading;
  color: $color-teal;
  font-size: 1.3em;
  font-weight: bold;
}

.o-heading2a {
  @extend %o-heading2a;
}

%o-heading3 {
  @extend %o-heading;
  font-size: 1.2em;
  font-weight: bold;
}

.o-heading3 {
  @extend %o-heading3;
}

%o-heading3a {
  @extend %o-heading;
  font-size: 1.2em;
  font-weight: normal;
}

.o-heading3a {
  @extend %o-heading3a;
}

%o-heading4 {
  @extend %o-heading;
  font-size: 1em;
  font-weight: bold;
}

.o-heading4 {
  @extend %o-heading4;
}

%o-heading4a {
  @extend %o-heading;
  margin-bottom: $spacing-sm;
  color: $color-teal;
  font-size: 1em;
  font-weight: bold;
}

.o-heading4a {
  @extend %o-heading4a;
}

%o-heading5 {
  @extend %o-heading;
  font-size: 1em;
  font-weight: bold;
  font-style: italic;
}
