// ##### Element Resets ##### //

// Set box sizing to border-box on all elements:

*,
*::before,
*::after {
  box-sizing: border-box;
}

abbr {
  border: none; // for older browsers
  text-decoration: none; // for newer browsers
  cursor: default;

  &[title] {
    border: none;
  }

}

// Set fonts to properties used within previous normalize.scss:
button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

// Make hidden attribute work on any element:
[hidden] {
  display: none;
}

summary {
  display: block; // Removes details arrow icon from moz browsers
  cursor: pointer;

  // Removes details arrow icon from webkit browsers:
  // sass-lint:disable no-vendor-prefixes
  &::-webkit-details-marker {
    display: none;
  }

}

// Remove details polyfill arrow icon (use ::after pseudo element in development instead of ::before)

html.no-details summary {

  &::before {
    display: none !important;
  }

}
