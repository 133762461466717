// ##### Element Color Picker Component ##### //

.c-elementcolorpicker {
  display: inline-flex;
  margin: 0;

  legend {
    font-weight: bold;
  }

  input {
    margin-right: 5px;
  }

  label {
    margin-right: $spacing-md;
  }
}
