// ##### Issue Component ##### //

.c-issue {
  margin-bottom: $spacing-md;

  @include bp(screen1) {
    display: flex;
    position: relative;
  }

  @include u-heading() {
    @extend %o-heading3a;
    margin-bottom: $spacing-sm;

    @include bp(screen1) {
      position: absolute;
      right: 0;
      left: $thumbnail-width + $spacing-md;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

  }

}

.c-issue__thumbnail {
  margin: 0 0 $spacing-sm;

  @include bp(screen1) {
    flex: 0 0 auto;
  }

  img {
    width: $thumbnail-width;
    height: auto;
    margin: 0 $spacing-base $spacing-base 0;
    border: 1px solid $color-light-gray;
    box-shadow: $box-shadow2;

    @include bp(screen1) {
      margin: 0 $spacing-md 0 0;
    }

  }

  figcaption {
    @include bp(screen1) {
      position: absolute;
      right: 0;
      bottom: 0;
      left: $thumbnail-width + $spacing-md;
      font-size: 0.9em;
    }

  }

}

.c-issue__description {

  @include bp(screen1) {
    margin-top: $spacing-lg;
    padding-bottom: $spacing-md;
  }

}

.c-issue__caption-truncate {

  @include bp(screen1) {
    max-height: 1.9em; // truncate to 1 line per jquery.dotdotdot
  }

}

// Button to show untruncated text:
.c-issue__caption-truncate-more {
  @extend %o-button__7;
  // "display: none"  is unnecessary, as truncation doesn't get triggered at this screen size.

  @include bp(screen1) {
    display: contents;
  }

}
