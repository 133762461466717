// ##### Informational Pages Component ##### //

/* in HTML but not styled:
.c-infopages {
}
*/

.c-infopages__show-more {
  padding-top: $spacing-md;
  border-top: 1px solid $color-dark-gray;

  @include bp(screen2) {
    display: grid;
    padding-top: 0;
    border-top: none;
    grid-template-columns: 2fr auto;
  }

  .c-infopages__items {

    @include bp(screen2) {
      grid-column: 1 / 3;
    }

  }

  [class^='c-pagination'] {
    margin: 0 $spacing-sm $spacing-base;

    @include bp(screen2) {
      justify-content: center;
      margin-top: $spacing-sm;
    }

  }

}

.c-infopages__items {

  @include bp(screen2) {
    display: flex;
    flex-wrap: wrap;
  }

}

.c-infopages__item {
  margin-bottom: $spacing-md;

  &:not(:first-child) {
    padding-top: $spacing-md;
    border-top: 1px solid $color-dark-gray;
  }

  @include bp(screen2) {
    flex: 1 1 25%; // 3 columns
    margin: $spacing-sm;
    padding: $spacing-sm;
    border: 1px solid $color-dark-gray;
  }

  @include bp(screen3) {
    padding: $spacing-md;
  }

  @include u-heading() {
    margin: 0;
    font-size: 1em;
    font-weight: normal;

    b {
      display: block;
      margin-bottom: $spacing-sm;
      color: $color-dark-gray;
      font-size: 0.8em;
      font-weight: bold;
      text-transform: uppercase;
    }

    a {
      @extend %o-textlink__secondary;
      display: block;
      margin-bottom: $spacing-sm;
    }

  }

}

.c-infopages__title {
  @extend %o-heading3;
}

.c-infopages__text {
  max-height: 4.2em; // truncate beyond 3 lines via jQuery dotdotdot
  line-height: $line-height2;
  overflow: hidden; // hide text beyond max-height
}

.c-infopages__toggle {
  @extend %o-button__7;
  display: flex;
  margin: $spacing-sm $spacing-sm 0 auto;
}
