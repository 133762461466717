// ##### Input Objects ##### //

// ***** Inline Input Objects ***** //

.o-input__inline {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-md;

  // Any input object:
  [class^='o-input__'] {
    
    &:not(:last-child) {
      margin-right: $spacing-md;
      margin-bottom: $spacing-sm;
    }

  }

}

.o-input__label--hidden {
  @extend %u-hide;
}

// ***** Dropdown Lists ***** //

%o-input__droplist {

  select {
    @extend %u-interactive-element-size;
    // text isn't centering in FF with 'min-height' within above placeholder, so need to use 'height' here:
    height: $pointer-size-fine;
    padding: 0 2em 0 $spacing-sm;
    border: 1px solid $color-dark-gray;
    border-radius: 0;
    background: inline('../images/icon_arrow-down.svg') no-repeat right $spacing-sm center / 0.8em;
    appearance: none;

    // sass-lint:disable no-vendor-prefixes
    &::-ms-expand { // remove default down arrow for IE 10 and higher
      display: none;
    }

  }

}

.o-input__droplist1 {
  @extend %o-input__droplist;

  label {
    margin-right: $spacing-sm;
    color: $color-dark-gray;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
  }

}

.o-input__droplist2 {
  @extend %o-input__droplist;

  label {
    display: block;
    margin-bottom: $spacing-base;
  }

}
