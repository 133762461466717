// ##### Text Link Objects ##### //

%o-textlink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

}

%o-textlink__primary {
  @extend %o-textlink;
  color: $color-dark-orange;
}

.o-textlink__primary {
  @extend %o-textlink__primary;
}

%o-textlink__secondary {
  @extend %o-textlink;
  color: $color-teal;
}

.o-textlink__secondary {
  @extend %o-textlink__secondary;
}

%o-textlink__black {
  @extend %o-textlink;
  color: inherit;
}

.o-textlink__black {
  @extend %o-textlink__black;
}

%o-textlink__white {
  @extend %o-textlink;
  color: $color-white;
}

.o-textlink__white {
  @extend %o-textlink__white;
}

%o-textlink__left-icon {
  @extend %u-interactive-element-size;
  display: inline-flex;
  align-items: center;
  padding-left: 1.5em;
  background: no-repeat left center / 1em;
}

.o-textlink__left-icon {
  @extend %o-textlink__left-icon;
  // Default textlink style:
  @extend %o-textlink__secondary;
  // Default icon below. To use a different icon, create a new class, @extend with %o-textlink__left-icon, and apply background-image property with different image path:
  background-image: inline('../images/icon_pdf-teal.svg');
}

%o-textlink__right-icon {
  @extend %o-textlink__secondary;
  display: inline-flex;
  align-items: center;
  padding-right: 1.7em;
  background: inline('../images/icon_external-link-teal.svg') no-repeat right center / 1.1em;
  font-weight: bold;
  text-align: right;
}

.o-textlink__external-link {
  @extend %o-textlink__right-icon;
}

%o-textlink__right-arrow {
  @extend %o-textlink__right-icon;
  padding-right: 1.4em;
  background-image: inline('../images/icon_angle-right-teal.svg');
}

.o-textlink__right-arrow {
  @extend %o-textlink__right-arrow;
}
