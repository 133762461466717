// ##### CMS Client Markup ##### //

.c-clientmarkup {

  // ***** Headings ***** //

  > h1 {
    @extend %o-heading1;
    margin-top: $spacing-md;
  }

  > h2 {
    @extend %o-heading2;
    margin-top: $spacing-md;
  }

  > h3 {
    @extend %o-heading3;
    margin-top: $spacing-md;
  }

  > h4 {
    @extend %o-heading4;
    margin-top: $spacing-md;
  }

  > h5 {
    @extend %o-heading5;
    margin-top: $spacing-md;
  }

  // ***** Links ***** //

  a {
    // Similar to c-pubinfo__link, but without the u-one-line-truncation mixin
    @extend %o-textlink__secondary;

    @include bp(screen1)
  }

  // ***** Lists ***** //

  ol,
  ul {
    margin-bottom: $spacing-md;

    li {

      &:not(:last-child) {
        margin-bottom: $spacing-sm;
      }

    }

  }

  // ***** Blockquotes ***** //

  blockquote {
    margin-bottom: $spacing-md;
    padding-left: $spacing-sm;
    font-size: 1.1em;
  }

}

#abstract-text {
  white-space: pre-wrap;
}

