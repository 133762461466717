// ##### Unit Carousel Component ##### //

.c-unitcarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-unitcarousel__scrollbox {
  height: 300px;
  margin-bottom: $spacing-sm;
  overflow: scroll;

  @include bp(screen2) {
    padding: 0 $campuscarouselframe-spacing;
  }

}

%c-unitcarousel__button {
  @extend %u-interactive-element-size;
  width: 3em;
  border: none;
  background: no-repeat center / 5em;
}

.c-unitcarousel__button-up {
  @extend %c-unitcarousel__button;
  margin-bottom: $spacing-sm;
  background-image: inline('../images/icon_angle-up-gray.svg');
}

.c-unitcarousel__button-down {
  @extend %c-unitcarousel__button;
  background-image: inline('../images/icon_angle-down-gray.svg');
}

.c-unitcarousel__item {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm;
  border-top: 1px solid $color-light-gray;

  @include bp(screen1) {

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 4.5em;
      grid-column-gap: $spacing-md;
    }

  }

  &:last-child {
    border-bottom: 1px solid $color-light-gray;
  }

  &:nth-child(odd) {
    background-color: $color-light-teal;
  }

  @include u-heading() {
    @extend %o-heading3a;

    a {
      @extend %o-textlink__secondary;
    }

  }

}

.c-unitcarousel__item-authorlist {
  grid-column: 1;

  .c-authorlist {

    @include bp(screen1) {

      @supports (display: grid) {
        margin-bottom: 0;
      }

    }

  }

}

%c-unitcarousel__item-type {
  align-self: center;
  margin-left: auto;
  padding-top: 2em;
  background: no-repeat top center / 1.5em;
  font-size: 0.8em;
  grid-column: 2;
  grid-row: 1 / 3;
  justify-self: center;

  @include bp(screen1) {

    @supports (display: grid) {
      margin-left: 0;
    }

  }

}

.c-unitcarousel__item-type--article {
  @extend %c-unitcarousel__item-type;
  background-image: inline('../images/icon_file-text-black.svg');
}

.c-unitcarousel__item-type--book {
  @extend %c-unitcarousel__item-type;
  background-image: inline('../images/icon_book.svg');
}

.c-unitcarousel__item-type--thesis {
  @extend %c-unitcarousel__item-type;
  background-image: inline('../images/icon_graduation-cap-black.svg');
}

.c-unitcarousel__item-type--multimedia {
  @extend %c-unitcarousel__item-type;
  background-image: inline('../images/icon_play-circle-o-black.svg');
}
