// ##### Data Table Component ##### //

$table-border-size: 1px;

.c-datatable {
  max-height: 100vh;
  overflow: auto;

  &.c-datatable-leftalign {
    td {
      text-align: left;
    }

    th[scope='row'] {
      font-size: .8em;
    }
  }

  table {
    margin-bottom: $spacing-md;
    border-collapse: collapse;

    caption {
      @extend %o-heading3;
      position: sticky;
      top: 0;
      margin: 0;
      padding: $spacing-sm;
      background-color: $color-white;
      text-align: left;
      z-index: 2;
    }

    th,
    td {
      padding: $spacing-sm;
      text-align: left;
    }

    th {
      text-align: left;
    }

    td {
      text-align: right;
    }



    // ***** Table Borders, Background, and Sticky Behavior ***** //

    thead {

      th[scope='col'] {
        position: sticky;
        top: -1px;
        background-color: $color-teal;
        color: $color-white;
        font-weight: normal;
        z-index: 2;

        &:first-child {
          left: -1px;
          z-index: 3;
        }

        &:not(:first-child) {
          border-left: $table-border-size solid $color-white;
        }

        // keeps a right border on cells when sticky:
        &::after {
          position: absolute;
          top: 0;
          right: -1px;
          bottom: 0;
          left: 0;
          border-right: 1px solid $color-white;
          content: '';
          pointer-events: none;
        }

        a {
          @extend %o-textlink__white;
          text-decoration: underline;
        }

      }

    }

    tbody {

      tr {

        th,
        td {
          border: $table-border-size solid $color-teal;
          background-color: $color-white;
        }

        &:nth-child(even) {

          th,
          td {
            background-color: lighten($color-light-gray, 15%);
          }

        }

      }

      th[scope='row'] {
        position: sticky;
        left: -1px;

        // keeps borders on cells when sticky:
        &::after {
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          left: 0;
          border: 1px solid $color-teal;
          content: '';
          pointer-events: none;
        }

        a {
          @extend %o-textlink__secondary;
          text-decoration: underline;
        }

      }

    }

  }

}
