// ##### Author List Component ##### //

.c-authorlist {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: $spacing-md;
}

.c-authorlist__year {
  align-self: flex-start;

  + .c-authorlist__list {
    margin-left: $spacing-sm;
    padding-left: $spacing-sm;
    border-left: 1px solid $color-black;
  }
}

.c-authorlist__list {
  @extend %u-reset-list-styles;
  max-height: 3.2em; // truncate beyond 3 lines per jquery.dotdotdot
  overflow: hidden; // hide text beyond max-height

  li {
    display: inline-block;
    margin-right: 0.3rem;

    a {
      @extend %o-textlink__secondary;
      color: black;
    }
  }
}

.c-authorlist__heading {
  font-size: 1.03em;
  font-weight: bold; 
}

.c-authorlist__list-more-link, .c-authorlist__list-more-link-working {
  @extend %o-textlink__secondary;

}

.c-authorlist__copyright {
  grid-column: 1 / 3;

  a {
    @extend %o-textlink__secondary;
  }
}
