// ##### Checkbox Component ##### //

%c-checkbox {
  @extend %u-reset-list-styles;

  ul {
    padding-left: 2em;

    @include u-interactive-element-pointer(coarse) {
      padding-left: 2.4em;
    }

  }

}

.c-checkbox {
  @extend %c-checkbox;
}

.c-checkbox--2column {
  @extend %c-checkbox;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 1 0 40%;

    &:nth-child(odd) {
      margin-right: $spacing-sm;
    }

  }

}

%c-checkbox__icon {
  background: no-repeat left top 0.3em / 1.3em;

  @include u-interactive-element-pointer(coarse) {
    background: no-repeat left top 0.5em / 1.8em; 
  }

}

.c-checkbox__input {
  @extend %u-hide;

  &:checked + .c-checkbox__label {
    @extend %c-checkbox__icon;
    background-image: inline('../images/icon_checkbox-checked.svg');
  }

}

.c-checkbox__label {
  @extend %c-checkbox__icon;
  display: block;
  padding: 0.5em 0 0.5em 1.9em;
  background-image: inline('../images/icon_checkbox-unchecked.svg');
  cursor: pointer;
  user-select: none;

  &:focus,
  &:hover {
    background-image: inline('../images/icon_checkbox-unchecked-orange.svg');
  }

  @include u-interactive-element-pointer(coarse) {
    padding: 0.8em 0 0.8em 2.4em;
  }

}

// ***** Attribution Checkboxes ***** //

%c-checkbox__attrib {
  background: no-repeat left 2em top 0.3em / 6.4em;

  @include u-interactive-element-pointer(coarse) {
    background: no-repeat left 2.4em top 0.5em / 9em;
  }

  .c-checkbox__label {
    padding-top: 1.7em;

    @include u-interactive-element-pointer(coarse) {
      padding-top: 2.3em;
    }

  }

}

.c-checkbox__attrib-cc-by-nc-nd {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-nc-nd-small.svg');
}

.c-checkbox__attrib-cc-by-nc-sa {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-nc-sa-small.svg');
}

.c-checkbox__attrib-cc-by-nc {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-nc-small.svg');
}

.c-checkbox__attrib-cc-by-nd {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-nd-small.svg');
}

.c-checkbox__attrib-cc-by-sa {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-sa-small.svg');
}

.c-checkbox__attrib-cc-by {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-by-small.svg');
}

.c-checkbox__attrib-cc-zero {
  @extend %c-checkbox__attrib;
  background-image: url('#{$media-path}cc-zero-small.svg');
}
