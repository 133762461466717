// ##### Top Link Component ##### //

.c-toplink {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-sm;

  @include bp(screen2) {
    margin-bottom: $spacing-md;
  }

  a {
    @extend %o-textlink__secondary;
    font-size: 1.2em;
    font-weight: bold;
  }

}
