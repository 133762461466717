// ##### Language Component ##### //

.c-language {

  a {

    &:not(:first-child) {
      margin-left: $spacing-sm;
      padding-left: $spacing-sm;
      border-left: 1px solid $color-dark-gray;
    }

  }

}

.c-language__small-label {

  @include bp(screen1) {
    display: none;
  }

}

.c-language__large-label {
  display: none;

  @include bp(screen1) {
    display: inline-block;
  }

}
