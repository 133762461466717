// ##### Breadcrumb Component ##### //

.c-breadcrumb {
  margin-bottom: $spacing-sm;

  ul {
    @extend %u-reset-list-styles;
  }

  li {
    display: inline-block;
    margin-bottom: $spacing-base;

    &:not(:last-child) {

      &::after {
        padding-left: 1.5em;
        background: inline('../images/icon_angle-right-black.svg') no-repeat center / contain;
        content: '';
      }

    }

    a {
      @extend %o-textlink__secondary;
      font-size: 0.9em;
    }

  }

}

.c-breadcrumb-link--active {
  font-weight: bold;
}
