// ##### Share Component ##### //

.c-share {
  display: inline-block;
  border-radius: $round-corner;
  box-shadow: $box-shadow2;

  summary {
    @extend %u-interactive-summary-size;
    display: inline-block;
    position: relative;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: $round-corner;
    background: $color-teal inline('../images/icon_share-square-o-white.svg') no-repeat left $spacing-sm center / 16px;
    color: $color-white;
    user-select: none;

    &::after {
      position: absolute;
      top: 0;
      right: $spacing-sm;
      bottom: 0;
      padding: 0 7px;
      background: inline('../images/icon_arrow-down-white.svg') no-repeat center / 13px;
      content: '';
    }

  }

  &[open] {

    summary::after {
      transform: rotate(180deg);
    }

  }

}

.c-share__list {
  position: absolute; // to c-itemactions
  right: 0;
  left: 0;
  margin-top: 5px;
  background-color: lighten($color-black, 35%);
  z-index: 1;

  @supports (backdrop-filter: blur()) {
    background-color: rgba($color-black, 0.6);
    backdrop-filter: blur(5px);
  }

  @include bp(screen3) {
    left: auto;
    width: 130px;
  }

}

%c-share__link {
  @extend %nav-item;
  padding-left: 40px;
  background: no-repeat left $spacing-sm center / 16px;
  color: $color-white;

  &:not(:first-child) {
    border-top: none;
  }

  @include bp(screen3) {
    border: none;
  }

}

.c-share__email {
  @extend %c-share__link;
  background-image: inline('../images/icon_envelope-o-white.svg');
}

.c-share__facebook {
  @extend %c-share__link;
  background-image: inline('../images/icon_facebook-white.svg');
}

.c-share__twitter {
  @extend %c-share__link;
  background-image: inline('../images/icon_twitter-white.svg');
}
