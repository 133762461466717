// ##### Campus Carousel Frame Component ##### //

.c-campuscarouselframe {
  margin-bottom: $spacing-md;
  padding: $spacing-md $spacing-sm;
  background-color: $color-white;
  box-shadow: $box-shadow1;

  @include bp(screen1) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: $spacing-sm;
  }

}

.c-campuscarouselframe__heading {
  @extend %o-heading3;

  @include bp(screen1) {
    margin-left: $campuscarouselframe-spacing;
    grid-column: 1 / 3;
  }

  a {
    @extend %o-textlink__secondary;
  }

}

.c-campuscarouselframe__carousel {
  margin-bottom: $spacing-md;

  @include bp(screen1) {

    @supports (display: grid) {
      margin-bottom: 0;
    }

  }

}

.c-campuscarouselframe__stats {
  display: flex;
  justify-content: space-around;

  @include bp(screen1) {

    @supports (display: grid) {
      flex-direction: column;
    }

  }

}
