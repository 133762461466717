// ##### Toggle List Component ##### //

/* in HTML but not styled:
.c-togglelist {
}
*/

.c-togglelist__buttons {
  margin-bottom: $spacing-sm;
  margin-left: $pointer-size-fine;

  @include u-interactive-element-pointer(coarse) {
    margin-left: $pointer-size-coarse;
  }

  button {

    &:first-child {
      margin-right: $spacing-sm;
    }

  }

}

.c-togglelist__mainlist {
  @extend %u-reset-list-styles;
  margin-left: $pointer-size-fine;

  @include u-interactive-element-pointer(coarse) {
    margin-left: $pointer-size-coarse;
  }

  li {
    position: relative;

    a {
      @extend %o-textlink__secondary;
      @extend %u-interactive-element-size;
      display: inline-flex;
      align-items: center;
      padding-top: 0.3em;
      padding-bottom: 0.3em;
    }

  }

}

.c-togglelist__sublist {

  summary {
    color: $color-teal;
    user-select: none;

    &::after { // ::before can't be used; see resets.scss
      @extend %u-interactive-element-size;
      position: absolute;
      top: 0;
      left: (-$pointer-size-fine);
      background: inline('../images/icon_plus-black.svg') no-repeat center / 1.1em;
      content: '';

      @include u-interactive-element-pointer(coarse) {
        left: (-$pointer-size-coarse);
      }

    }

  }

  &[open] {

    > summary {

      &::after {
        background-image: inline('../images/icon_minus-black.svg');
      }

    }

  }

  ul {
    @extend %u-reset-list-styles;
    margin-left: $pointer-size-fine;

    @include u-interactive-element-pointer(coarse) {
      margin-left: $pointer-size-coarse;
    }

  }

}
