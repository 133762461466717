// ##### Statistics Carousel Component ##### //

.c-statcarousel {
  @extend %statcarousel-flickity-customizations;
  position: relative;
  margin-bottom: $spacing-md;
  padding: $spacing-md 2.5em;
  background-color: $color-white;
  box-shadow: $box-shadow1;
}

.c-statcarousel__section-heading {
  position: absolute;
  top: 0;
  bottom: 0;

  @include u-heading() {
    position: absolute;
    width: max-content;
    margin: 0;
    color: $color-dark-gray;
    font-size: 1.1em;
    font-weight: normal;
  }

  &:not(:first-child) {
    margin-left: $spacing-sm;
    padding-left: $spacing-sm;
    border-left: 1px solid $color-dark-gray;

    @include bp(screen1) {
      margin-left: 0;
      padding-left: 0;

      @include u-heading() {
        padding-left: $spacing-sm;
      }

    }

  }

}

.c-statcarousel__cell {
  margin-top: $spacing-lg;

  @include bp(screen1) {
    width: 15vw;
  }

}

// ***** Flickity Customizations ***** //

%statcarousel-flickity-customizations {

  .flickity-prev-next-button {
    width: 3.8em;
    height: 3.8em;
    background: transparent;

    &:hover {
      background: transparent;
    }

    &.previous {
      left: -0.6em;
    }

    &.next {
      right: -0.6em;
    }

    .arrow {
      fill: $color-dark-gray;
    }

  }

}
