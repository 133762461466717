// ##### Download Object ##### //

// ***** Toggle and Button ***** //

.o-download {
  display: inline-flex;
  border-radius: 0 $round-corner $round-corner 0;
  box-shadow: $box-shadow2;

  @include bp(screen3) {
    position: relative;
  }

}

.o-download__button {
  display: flex;
  align-items: center;
  padding: 0 $spacing-sm;
  border-right: 1px solid $color-white;
  border-radius: $round-corner 0 0 $round-corner;
  background-color: $color-teal;
  color: $color-white;
  text-decoration: none;
}

.o-download__formats {

  summary {
    @extend %u-interactive-element-size; // sets the height for .o-download
    position: relative;
    border-radius: 0 $round-corner $round-corner 0;
    background-color: $color-teal;
    user-select: none;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inline('../images/icon_arrow-down-white.svg') no-repeat center / 0.8em;
      content: '';

    }

  }

  &[open] {

    summary::after {
      transform: rotate(180deg);
    }

  }

}

// ***** Menu and Menu Items ***** //

%o-download__menu {
  @extend %u-reset-list-styles;
  position: absolute; // to c-itemactions
  right: 0;
  left: 0;
  margin-top: 5px;
  background-color: lighten($color-black, 35%);
  z-index: 1;

  @supports (backdrop-filter: blur()) {
    background-color: rgba($color-black, 0.6);
    backdrop-filter: blur(5px);
  }

  @include bp(screen3) {
    right: 0;
    left: 0;
  }

}

// ***** Single Menu Items ***** //

.o-download__single-menu {
  @extend %o-download__menu;

  li {

    &:not(:first-child) {

      a {
        border-top: none;
      }

    }

    a {
      @extend %nav-item;
      background: none;
      color: $color-white;
    }

  }

}

// ***** Nested Menu Items ***** //

.o-download__nested-menu {
  @extend %o-download__menu;

  @include bp(screen3) {
    padding-bottom: $spacing-sm;
  }

}

%o-download__nested-item {
  @extend %nav-item;
  margin-left: -40px;
  padding-left: 40px;
  background: none;
  color: $color-white;
  font-size: 1em;

  @include bp(screen3) {
    padding-top: 3px;
    padding-bottom: 3px;
    border: none;
  }

}

%o-download__nested-list {
  padding-top: $spacing-sm;
  padding-left: 40px;
  background: no-repeat left 10px top 10px / 16px;
  color: $color-white;

  ul {
    @extend %u-reset-list-styles;
    margin-top: $spacing-sm;

    li {

      a {
        @extend %o-download__nested-item;

        @include bp(screen3) {
          text-decoration: underline;
        }

      }

      &:not(:first-of-type) a {
        border-top: none;
      }

    }

  }

}

// ***** Nested Menu Item Icons ***** //

@each $download-heading-number, $download-img-label in
  (1, files-o-white),
  (2, quote-right-white),
  (3, play-circle-white) {

  .o-download__nested-list#{$download-heading-number} {
    @extend %o-download__nested-list;
    background-image: inline('../images/icon_#{$download-img-label}.svg');
  }

}
