// ##### Nav Bar Component ##### //

.c-navbar {
  @extend %u-page-bleed;
  display: flex;
  align-items: center;
  margin-bottom: $spacing-sm;
  padding: $spacing-sm;
  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
  background-color: $color-white;

  @include bp(screen4) {
    padding: $spacing-sm $spacing-lg;
  }

}
