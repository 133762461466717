// ##### Navigation Component ##### //

.c-nav {
  flex: 1 0 auto; // to .c-navbar flexbox
  z-index: 1; // prevents stacking under marquee carousel in some browsers

  ul {
    @extend %u-reset-list-styles;
  }

}

.c-nav__main {

  &[open] {

    .c-nav__main-button::after {
      transform: rotate(180deg);
    }

  }

}

.c-nav__main-button {
  @extend %u-interactive-summary-size;
  display: inline-block;
  position: relative;
  padding-right: 2em;
  padding-left: 0.6em;
  border: 1px solid $color-dark-gray;
  color: $color-dark-gray;
  user-select: none;

  @include bp(screen3) {
    display: none;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: 6px;
    padding: 7px;
    background: inline('../images/icon_arrow-down.svg') no-repeat center / 13px;
    content: '';
  }

}

// ***** Main Navigation Items ***** //

%c-nav__item {
  border: 1px solid $color-dark-gray;
  background: $color-white;

  &:not(:first-child) {
    border-top: none;
  }

  @include bp(screen3) {

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

  }

  a,
  button {
    @extend %u-interactive-summary-size;
    display: flex;
    align-items: center;
    padding-right: $spacing-sm;
    padding-left: $spacing-sm;
    color: $color-dark-gray;
    font-size: 1.1em;
    text-decoration: none;

    &:hover {
      color: $color-dark-orange;
    }

  }

}

%c-nav__main-items {
  position: absolute; // to <body>
  right: $spacing-sm;
  left: $spacing-sm;
  margin-top: $spacing-base !important;
  transition: transform 0.5s;

  @include bp(screen3) {
    display: flex;
    position: static;
    align-items: center;
    justify-content: space-around;
    margin-top: 0 !important;
  }

  > li {
    @extend %c-nav__item;

    &:not(:first-child) {
      border-top: none;
    }

    @include bp(screen3) {
      border: none;
      background: none;
    }

    .c-nav__item--active {
      color: $color-black;
      font-weight: bold;
    }

  }

}

.c-nav__main-items {
  @extend %c-nav__main-items;
}

// toggle this on presence of 'open' attr on c-nav__sub to show/hide c-nav__sub-items
.c-nav__main-items--submenu-active {
  @extend %c-nav__main-items;
  transform: translateX(-103%);

  @include bp(screen3) {
    transform: translateX(0);
  }

}

// ***** Sub Navigation (exists in NavSubComp.jsx) ***** //

.c-nav__sub {
  flex: 1 0 auto;
  background-color: $color-white;

  @include bp(screen3) {
    position: relative;

    &[open] {

      .c-nav__sub-button::after {
        transform: rotate(180deg);
      }

    }

  }

}

.c-nav__sub-button {
  @extend %u-interactive-summary-size;
  position: relative;
  padding-right: 2.1em;
  padding-left: $spacing-sm;
  border: none;
  color: $color-dark-gray;
  font-size: 1.1em;
  user-select: none;

  &:hover {
    color: $color-dark-orange;
  }

  @include bp(screen3) {
    border: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: $spacing-sm;
    bottom: 0;
    width: 1em;
    background: inline('../images/icon_angle-double-right-gray.svg') no-repeat center / contain;
    content: '';

    @include bp(screen3) {
      width: 0.8em;
      background-image: inline('../images/icon_arrow-down.svg');
    }

  }

}

.c-nav__sub-items {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateX(103%); // moves sub-items to right off-screen of main-items

  // Drop-down menu:

  @include bp(screen3) {
    top: auto;
    width: 12em;
    max-height: 23em;
    margin-top: $spacing-base;
    transform: translateX(0);
    border-top: 1px solid $color-dark-gray;
    border-bottom: 1px solid $color-dark-gray;
    overflow: auto;
  }

  li {
    @extend %c-nav__item;
  }

}

.c-nav__sub-items-button {
  @extend %c-nav__item;
  width: 100%;
  margin-right: $spacing-sm;
  padding-left: 2em !important;
  border-bottom: none;
  background: $color-white inline('../images/icon_angle-double-left-gray.svg') no-repeat left $spacing-sm center / 1em;
  text-align: left;

  @include bp(screen3) {
    display: none !important;
  }

}
