// ##### Styles for pdf.js viewer ##### //

// scss-lint:disable IdSelector
#pdfjs-cdl-wrapper {
  @import '../node_modules/pdfjs-embed2/dist/viewer'; // Note! ".css" in path disables wrapping. Avoid.

  @include bp(screen1) {
    display: block;
  }

  background-color: $color-light-black;
  outline: none;
  font: message-box;
  font-size: 10px;

  /* Overrides, to keep number of changes in the vendor file very small */
  #sidebarContainer, #toolbarViewer {
    display: none;
  }

  #outerContainer {
    margin-top: 30px; /* was: 10px */
  }

  #mainContainer {
    position: relative; /* was "absolute" */
  }

  /* Styles for Loading Status */
  .toolbar {
    z-index: 9;
  }

  #toolbarContainer, #loadingBar {
    box-shadow: 0px;
    height: 16px;
    border-bottom: none;
  }
  /* End styles for Loading status */


  /* Turn off a bunch of button features we don't need in our UI */
  #viewerContainer {
    position: relative; /* was: absolute */
    top: auto;          /* was: 32px */
    right: auto;        /* was: 0 */
    bottom: auto;       /* was: 0 */
    left: auto;         /* was: 0 */
    width: 100%;        /* didn't previously specify width */
    overflow: hidden;   /* was: auto */
  }

  /* remove borders */
  .pdfViewer .page {
    margin: 2px;
    border: 2px;
    width: auto;
  }
}
