// ##### Media File Grid Component ##### //

.c-mediafilegrid {
  @extend %u-clearfix;

  @include bp(screen2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  // If CSS grid isn't supported, float mediafile objects left & right to simulate 2-column grid:

  div[class^='o-mediafile--'] {

    @include bp(screen2) {
      width: 50%;

      &:nth-child(odd) {
        float: left;
        clear: left;
      }

      &:nth-child(even) {
        float: right;
      }

      @supports (display: grid) {
        width: auto;
      }

    }

  }

}
