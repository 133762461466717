// ##### Publication Component ##### //

.c-pub {
  display: inline-block;
  width: 100%;
  margin-bottom: $spacing-md;
  break-inside: avoid;
}

.c-pub__heading {
  @extend %o-heading2;
  margin: 0.8em 0;

  @include bp(screen1) {
    margin: 0 0 0.8em;
  }

  a {
    @extend %o-textlink__secondary;
  }

}

.c-pub__subheading {
  margin-bottom: 0.4em;
  font-size: 1.2em;
}

.c-pub__abstract {
  max-height: 8em; // truncate beyond 6 lines per jquery.dotdotdot
  margin-bottom: $spacing-md;
  overflow: hidden; // hide text beyond max-height
}
