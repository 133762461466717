// ##### Facet Box Component ##### //

.c-facetbox {
  @extend %u-clearfix;
  margin-bottom: $spacing-sm;
  padding: 0 $spacing-sm;

  fieldset {
    @extend %u-reset-fieldset-styles;
  }

}

.c-facetbox__summary {
  @extend %o-heading3;
  position: relative;
  margin: 0 (-$spacing-sm);
  padding: 0 1.8em 0 $spacing-sm;
  background: $color-light-gray;
  font-weight: normal;
  box-shadow: $box-shadow1;
  user-select: none;

  &::after {
    position: absolute;
    top: 0;
    right: $spacing-sm;
    bottom: 0;
    padding: 7px;
    background: inline('../images/icon_sort-down-black.svg') no-repeat center / 0.8rem;
    content: '';
  }

  // <summary> isn't responding to flexbox values in Safari, so need to use <span> child with those values:
  span {
    @extend %u-interactive-element-size;
    display: inline-flex;
    align-items: center;
    padding: $spacing-base 0;
  }

}

.c-facetbox[open] {
  padding-bottom: $spacing-base;
  border-bottom: 1px solid $color-light-gray;

  .c-facetbox__summary {
    background: none;
    box-shadow: none;

    &::after {
      transform: rotate(180deg);
    }

  }

}

.c-facetbox__show-more {
  @extend %u-interactive-element-size;
  padding: 0 1em 0 0;
  float: right;
  border: none;
  background: inline('../images/icon_angle-double-right-teal.svg') no-repeat center right / 0.9em;
  color: $color-teal;
}
