// ##### Marquee Component ##### //

$marquee-height: 15.6em;
$marquee-overlay-size: 50%;

.c-marquee {
  @extend %marquee-flickity-customizations;

  @include bp(screen2) {
    display: flex;
  }

}

.c-marquee__sidebar {
  flex: 0 0 $column-width;

  @include bp(screen2) {
    margin-left: $spacing-md;
  }

  p {
    font-size: 1.1em;
  }

}

.c-marquee__sidebar-truncate {

  @include bp(screen2) {
    height: $marquee-height - 3.5em;
    overflow: hidden;
  }

}

.c-marquee__sidebar-more {
  @extend %o-button__7;
}

.c-marquee__carousel {
  flex: 1;
  margin-bottom: $spacing-md;

  @include bp(screen2) {
    height: $marquee-height;
  }

}

.c-marquee__carousel-cell {
  position: relative;
  width: 100%;
  height: $marquee-height;
  padding: $spacing-md 4.4em;
  background: right center / cover no-repeat;
  // background-image url property/value is set as an inline style after this class within the component
  z-index: -1;

  &::after {
    @include u-background-filter($color-black);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: -1;
  }

  @include bp(screen1) {
    padding: $spacing-md $marquee-overlay-size $spacing-md 4.4em;

    &::after {
      right: $marquee-overlay-size;
      margin-right: (-$spacing-md);
    }

  }

  @include u-heading() {
    @extend %o-heading1;
    color: $color-white;
  }

  p {
    margin-bottom: $spacing-md;
    color: $color-white;
    font-size: 1.1em;
  }

  a {
    @extend %o-textlink__white;
    display: inline-block;
    text-decoration: underline;
  }

}

// ***** Flickity Customizations ***** //

.flickity-page-dots {
  bottom: $spacing-sm;

  .dot {
    background: $color-white;
  }

}

%marquee-flickity-customizations {

  .flickity-prev-next-button {
    width: 5em;
    height: 5em;
    background: transparent;

    &:hover {
      background: transparent;
    }

    &.previous {
      left: 0;
    }

    &.next {
      right: 0;
    }

    .arrow {
      fill: $color-white;
    }

    .no-svg {
      color: $color-white;
    }

  }

}
