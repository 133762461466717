// ##### Modal Component ##### //

/* in HTML but not styled:
.c-modal {
}
*/

.c-modal--open {
  position: absolute;
  top: 10vh;
  right: 10vw;
  bottom: 10vh;
  left: 10vw;
  border: 1px solid $color-light-gray;
  border-radius: $round-corner;
  outline: none;
  background-color: $color-white;
  box-shadow: 5px 5px 15px $color-light-black;
  overflow: auto;
  max-height: 50vh;

  @include bp(screen1) {
    top: 15vh;
    right: 15vw;
    bottom: 15vh;
    left: 15vw;
  }

  @include bp(screen3) {
    top: 20vh;
    right: 20vw;
    bottom: 20vh;
    left: 20vw;
  }

}

.c-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-light-black, 0.8);
  z-index: 9999; // stack above all components that contain z-indexes

  @supports (backdrop-filter: blur()) {
    background-color: rgba($color-black, 0.6);
    backdrop-filter: blur(5px);
  }

}

.modal__header {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  min-height: 2.5em;
  padding: 0 $spacing-md;
  background-color: $color-light-gray;

  @include u-heading() {
    margin: 0;
    font-size: 1.2em;
    font-weight: normal;
  }

}

.modal__content {
  padding: $spacing-md;
}

.c-modal__footer {
  display: flex;
  position: sticky;
  bottom: 0;
  padding: $spacing-sm $spacing-md;
  border-top: 1px solid $color-light-gray;
  background-color: $color-white;

  button {
    
    &:not(:first-child) {
      margin-left: $spacing-sm;
    }

  }

}

.c-modal__header-close {
  @extend %o-button__9;
  margin-left: auto;
  background-image: inline('../images/icon_close-black.svg');
  background-size: 1em;

  span {
    @extend %u-hide;
  }

}

// rename to '.c-modal__footer-close' after adding header-close buttons to all modals:
.c-modal__button-close {
  @extend %o-button__2;
}
