// ##### Sort By Component ##### //

.c-sort {
  display: flex;
  margin-right: $spacing-md;
  margin-bottom: $spacing-md;
}

.c-sort__page-input {
  margin-left: $spacing-md;
}
