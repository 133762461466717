// ##### Column Box Objects ##### //

%o-columnbox {
  @extend %u-responsive-images;
  margin-bottom: $spacing-sm;
  padding: $spacing-sm $spacing-sm 0;

  header {
    margin: (-$spacing-sm) (-$spacing-sm) $spacing-sm;
    background: $color-light-gray;

    @include u-heading() {
      @extend %o-heading3a;
      display: inline-block;
      margin: 0 !important;
      padding: 0.4em $spacing-sm;
    }

  }
  
  @include bp(screen1) {
    padding: $spacing-lg $spacing-lg 0;

    header {
      margin: (-$spacing-lg) (-$spacing-lg) $spacing-md;

      @include u-heading() {
        padding: 0.4em $spacing-md;
      }

    }

  }

  @include bp(screen2) {
    margin-bottom: $spacing-md;
  }

}

.o-columnbox1 {
  @extend %o-columnbox;
  position: relative;
  padding: $spacing-sm;
  background: $color-white;
  box-shadow: $box-shadow1;

  @include bp(screen1) {
    padding: $spacing-lg;
  }

  // Columnbox1 overlay and spinner elements triggered by '--is-loading-data':

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.2s;
    background-color: $color-white;
    opacity: 0;
    pointer-events: none;

    // only render this element if pointer-events is supported to prevent blocked pointer/touch access to underlying links, buttons, text, etc:
    @supports (pointer-events: none) {
      content: '';
    }

  }

  &.is-loading-data {

    &::before {
      opacity: 0.8;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('#{$media-path}spinner.gif') no-repeat top 3em center / 3em;
      content: '';
    }

  }

}

.o-columnbox2 {
  @extend %o-columnbox;
}

// ***** When Placed Within Sidebar ***** //

aside {

  @include bp(screen1) {

    .o-columnbox1 {
      padding: $spacing-md;
    }

    .o-columnbox2 {
      padding: $spacing-md $spacing-md 0;
    }

    .o-columnbox1,
    .o-columnbox2 {

      header {
        margin: (-$spacing-md) (-$spacing-md) $spacing-md;
      }

    }

  }

}

// ***** If jQuery Dotdotdot Used ***** //

.o-columnbox__truncate1 {
  max-height: 5.5em; // truncate to 5 lines per jquery.dotdotdot
  overflow: hidden; // hide text beyond max-height
}

// Button to show untruncated text:
.o-columnbox__truncate-more {
  @extend %o-button__7;
}
