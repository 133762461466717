// ##### Content Well Object ##### //

%o-well {
  padding: $spacing-sm;

  @include bp(screen2) {
    padding: $spacing-md;
  }

  a {
    @extend %o-textlink__secondary;
  }

}

// ***** Well with colored background ***** //

.o-well-colored {
  @extend %o-well;

  margin-bottom: $spacing-md;

  background-color: $color-light-teal;
}

// ***** Large (well padded) Well ***** //

.o-well-large {
  @extend %o-well;

  padding: $spacing-xl $spacing-sm;

  @include bp(screen2) {
    padding: $spacing-xl $spacing-md;
  }
}
