// ##### Campus Hero Component ##### //

$campushero-text-shadow: 1px 1px 1px $color-light-black;

.c-campushero {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm;
  border-bottom: 1px solid $color-light-gray;
  background: $color-light-teal;

  // This prevents the inline background-image style referenced in the HTML from appearing up to the screen3 breakpoint size:
  @media (max-width: $screen3 - 1) {
    background-image: none !important;
  }

  @include bp(screen3) {
    display: grid;
    align-content: center;
    height: 30vw;
    padding: 0;
    background: $color-teal no-repeat center / cover;
    // background-image property/URL is set as an inline HTML style on this element
    z-index: 0;
    grid-template-columns: 60% auto;
  }

}

%c-campushero__text-bg {

  @include bp(screen3) {
    padding: 0 10% $spacing-md $spacing-lg;
    background-color: rgba($color-teal, .7);

    @supports (display: grid) {
      background-color: transparent;
      background-image: linear-gradient(to right, rgba($color-teal, .7) 85%, transparent);
    }

  }

}

.c-campushero__lede {
  @extend %u-sourcesansprolight-font-stack;
  @extend %c-campushero__text-bg;
  margin: (-$spacing-sm) (-$spacing-sm) $spacing-sm;
  padding: $spacing-sm;
  background-color: $color-teal;
  color: $color-white;
  font-size: 1.3em;
  text-align: center;

  @include bp(screen3) {
    margin: 0;
    padding: 0 0 $spacing-md $spacing-lg;
    padding-top: $spacing-md;
    background-color: rgba($color-teal, .7);
    font-size: 3.5vw;
    font-weight: normal;
    text-align: left;
    text-shadow: $campushero-text-shadow;
    grid-column: 1;

    @supports (display: grid) {
      background-color: transparent;
      background-image: linear-gradient(to right, rgba($color-teal, .7) 85%, transparent);
    }

  }

  span {

    @include bp(screen3) {
      display: block;
    }

  }

}

.c-campushero__text {
  @extend %c-campushero__text-bg;
  margin-bottom: $spacing-sm;
  line-height: $line-height1;

  @include bp(screen3) {
    margin-bottom: 0;
    color: $color-white;
    font-size: 1.2em;
    text-shadow: $campushero-text-shadow;
    grid-column: 1;
  }

}

.c-campushero__link {
  @extend %c-campushero__text-bg;
  align-self: flex-end;

  @include bp(screen3) {
    display: flex;
    padding-bottom: $spacing-md;
    grid-column: 1;
  }

  a {
    @extend %o-textlink__right-arrow;

    @include bp(screen3) {
      margin-left: auto;
      background-image: inline('../images/icon_angle-right-white.svg');
      color: $color-white;
      font-size: 1.2em;
      font-weight: normal;
      text-shadow: $campushero-text-shadow;
    }

  }

}
