// ##### Refine Component ##### //

.c-refine--no-drawer {

  > button { // open/close buttons
    display: none;
  }

}

.c-refine--has-drawer {
  position: relative;

  [class^='c-refine__drawer'] {
    position: absolute;
    z-index: 1;
  }

}

%c-refine__button {
  @extend %o-button__8;
  width: 100%;
  margin-bottom: $spacing-sm;
  background-color: $color-teal;
  background-repeat: no-repeat;
  background-size: 1.2em;
}

.c-refine__button--open {
  @extend %c-refine__button;
  background-image: inline('../images/icon_angle-double-right-white.svg');
  background-position: right $spacing-sm center;
}

.c-refine__button--close {
  @extend %c-refine__button;
  background-image: inline('../images/icon_angle-double-left-white.svg');
  background-position: left $spacing-sm center;
}

%c-refine__drawer {
  width: 100%;
  transition: transform 0.5s;
  background-color: lighten($color-light-gray, 15%);
}

.c-refine__drawer--opened {
  @extend %c-refine__drawer;
}

.c-refine__drawer--closed {
  @extend %c-refine__drawer;
  transform: translateX(-110%);
}
