// ##### Home Section Component ##### //

// ***** Styles Shared Between Homesections 1 - 3 ***** //

// box and header:
.c-homesection {
  margin-bottom: $spacing-sm;
  padding: $spacing-sm;
  background-color: $color-white;
  box-shadow: $box-shadow1;

  @include bp(screen3) {
    margin-bottom: $spacing-md;
    padding: $spacing-lg;
  }

  header {
    margin: (-$spacing-sm) (-$spacing-sm) $spacing-sm;
    padding: $spacing-sm;
    background-color: $color-light-gray;

    @include bp(screen3) {
      margin: 0 0 $spacing-lg;
      padding: 0;
      background: none;
    }

    h2 {
      margin: 0;
      font-size: 1.2em;
      font-weight: normal;

      @include bp(screen3) {
        font-size: 2em;
        text-align: center;
      }

    }

  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.2em;
    color: $color-teal;
    font-size: 1em;
    font-weight: bold;

    @include bp(screen3) {
      margin-bottom: 0.4em;
      font-size: 1.6em;
      font-weight: normal;
    }

  }

  p {

    @include bp(screen3) {
      font-size: 1.1em;
    }

  }

}

%c-homesection__grid {
  display: grid;
  padding-bottom: $spacing-sm;
  grid-gap: $spacing-md;

  @include bp(screen3) {
    padding-bottom: 0;
    grid-gap: $spacing-lg;
  }

  // add bottom margin to grid children if grid not supported:
  > * {

    &:not(:last-child) {
      margin-bottom: $spacing-md;

      @supports (display: grid) {
        margin-bottom: 0;
      }

    }

  }

}

%c-homesection__link {
  @extend %o-textlink__right-arrow;
  display: block;
  align-self: center;
  justify-self: right;
  grid-column: 1 / -1;

  @include bp(screen3) {
    font-size: 1.2em;
  }

}

// ***** Home Section 1 ***** //

.c-homesection__1 {
  @extend %c-homesection__grid;
  grid-template-columns: 1fr;

  @include bp(screen3) {
    background: url('#{$media-path}world-map-teal.svg') no-repeat center / contain;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr auto;
  }

  .o-stat {

    @include bp(screen3) {
      grid-column: 2;
    }

  }

}

.c-homesection__1-description {

  @include bp(screen3) {
    grid-row: 1 / 3;
  }

}

.c-homesection__1-metrics-heading {

  @include bp(screen3) {
    grid-column: 2;
  }

}

.c-homesection__1-link {
  @extend %c-homesection__link;
}

// ***** Home Section 2 ***** //

.c-homesection__2 {
  @extend %c-homesection__grid;
  grid-template-columns: repeat(2, 1fr);

  @include bp(screen3) {
    grid-template-columns: repeat(4, 1fr);
  }

  .o-stat {
    grid-column: 1 / 3;

    @include bp(screen3) {
      grid-column: 3 / 5;
    }

  }

}

.c-homesection__2-map {
  display: none;

  @include bp(screen3) {
    display: block;
    background: url('#{$media-path}california-map.svg') no-repeat center / contain;
    grid-column: 1 / 3;
    grid-row: 1 / 5;
  }

}

.c-homesection__2-description {
  grid-column: 1 / 3;

  @include bp(screen3) {
    grid-column: 3 / 5;
  }

}

.c-homesection__2-deposit {
  @extend %o-button__8;
  justify-self: center;

  @include bp(screen3) {
    grid-column: 3;
  }

}

.c-homesection__2-browse-campuses {
  @extend %c-homesection__link;
  justify-self: center;
  grid-column: 2;

  @include bp(screen3) {
    grid-column: 4;
  }

}

.c-homesection__2-metrics-heading {
  grid-column: 1 / 3;

  @include bp(screen3) {
    grid-column: 3 / 5;
  }

}

.c-homesection__2-browse-all {
  @extend %c-homesection__link;
}

.c-homesection__2-more {
  @extend %c-homesection__link;
}

// ***** Home Section 3 ***** //

.c-homesection__3 {
  @extend %c-homesection__grid;
  grid-template-columns: 1fr;

  @include bp(screen3) {
    grid-template-columns: repeat(2, 1fr);
  }

}

.c-homesection__3-description {

  @include bp(screen3) {
    align-self: center;
  }

}

.c-homesection__3-link {
  @extend %c-homesection__link;
}
