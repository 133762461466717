// ##### Withdraw Modal Component ##### //

.c-withdrawmodal__warning {
  margin-bottom: 10px;
}

.c-withdrawmodal__label {
  margin-bottom: 5px;
  font-weight: 700;
}

.c-withdrawmodal__public-message,
.c-withdrawmodal__internal-comment {
  display: block;
  width: 600px;
  margin-bottom: 10px;
}

.c-withdrawmodal__redirect-to {
  display: block;
  width: 100px;
  margin-bottom: 10px;
}
