// ##### Background Color Picker Component ##### //

.c-backgroundcolorpicker {
  display: inline-flex;

  label {
    margin-right: 10px;
    font-weight: bold;
  }
}
