// ##### adminbar Component ##### //

.c-adminbar {
  height: 3em;
  padding: 0.5em;
  background: $color-dark-gray;
  color: $color-white;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  justify-content: space-between;
}

.c-adminbar__logged-in-msg {
  display: inline-block;
  margin-left: 10px;
  flex: 0 1 auto;
}

.c-adminbar__edit-pg {
  flex: 0 1 auto;
  display: flex;
  height: 1.6em;
}

.c-adminbar__edit-pg-button {
  flex: 0 1 auto;
  color: $color-black;
}

.c-adminbar__edit-pg-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.c-adminbar-help__icon {
  flex: 0 1 auto;
  margin-left: 1.2em;
}

