// ##### Journal Objects ##### //

%o-journal {
  display: block;

  figure {
    height: 100%;
    margin: 0;

    img {
      display: block;
      height: 100%;
    }

  }

}

.o-journal1 {
  @extend %o-journal;
  @extend %o-textlink__secondary;
  width: 7.5em;

  &:not(:last-child) {
    margin-bottom: $spacing-md;
  }

  img {
    max-width: 100%;
  }

  figcaption {
    margin-top: $spacing-base;
  }

}

.o-journal2 {
  @extend %o-journal;
  display: inline-block;
  position: relative;
  height: 14em;
  border: 1px solid $color-teal;

  figcaption {
    @include u-background-filter($color-black);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3.4em;
    margin: 0;
    padding: $spacing-sm;
    color: $color-white;
    overflow: hidden;
  }

}
