// ##### Published Location Component ##### //

.c-publocation {
  position: relative;
  margin-bottom: $spacing-sm;

  @include bp(screen1) {

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(2, auto);
    }
    
  }

}

.c-publocation__heading {

  @include u-heading() {
    @extend %o-heading4;
    margin-bottom: $spacing-sm;
  }

}

.c-publocation__link {
  @extend %o-textlink__secondary;
  @include u-overflow-wrap();
  display: block;
  margin-bottom: $spacing-sm;

  @include bp(screen1) {
    margin-right: 120px; // sets width of license image
    margin-bottom: 0;
    padding-right: $spacing-md;

    @supports (display: grid) {
      margin-right: 0;
    }

  }

}

.c-publocation__license {

  @include bp(screen1) {
    position: absolute;
    top: 0;
    right: 0;

    @supports (display: grid) {
      position: static;
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }

  }

}
