// ##### Tabs Component ##### //

$tab-height: $element-height;

/* in HTML but not styled:
.c-tabs {
}
*/

%c-tabs__buttons {
  @extend %u-interactive-element-size;
  padding: 5px $spacing-sm;
  border: none;
  background-color: $color-light-gray;
  font-size: 1.1em;
  user-select: none;
}

.c-tabs__button {
  @extend %c-tabs__buttons;
}

.c-tabs__button--active {
  @extend %c-tabs__buttons;
  background-color: $color-white;
}

%c-tabs__tabs {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: $spacing-sm;

  @include bp(screen1) {
    grid-template-columns: repeat(3, auto);
  }

  @include bp(screen3) {
    grid-template-columns: repeat(6, auto);
  }

  button {
    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
  }

  @supports (display: grid) {

    button {
      margin: 0;
    }

  }

}

.c-tabs__tabs {
  @extend %c-tabs__tabs;

  @supports (display: grid) {

    button {

      &:nth-child(n+3) {
        display: none;
      }

      @include bp(screen1) {

        &:nth-child(n+3) {
          display: block;
        }

        &:nth-child(n+4) {
          display: none;
        }

      }

      @include bp(screen3) {

        &:nth-child(n+4) {
          display: block;
        }

      }

    }

  }

}

.c-tabs__tabs--show-all {
  @extend %c-tabs__tabs;
}

.c-tabs__button-more {
  @extend %c-tabs__buttons;
  display: none;
  width: 40px;
  justify-self: end;
  grid-column: 2 / 3;
  grid-row: 1 / 2;

  @supports (display: grid) {
    display: block;
  }

  @include bp(screen1) {
    grid-column: 3 / 4;
  }

  @include bp(screen3) {
    display: none;
  }

}

.c-tabs__content {
  margin: (-$spacing-sm) (-$spacing-sm) $spacing-sm;
  padding: $spacing-md $spacing-sm;
  background-color: $color-white;
  box-shadow: $box-shadow1;

  @supports (display: grid) {
    margin-top: 0;
  }

  @include bp(screen2) {
    margin-right: 0;
    margin-left: 0;
    padding: $spacing-md $spacing-sm $spacing-sm;
  }

}
