// ##### Search1 Component ##### //

.c-search1 {
  display: flex;
  position: fixed; // prevents horizontal overflow and scrollbar on small screens
  right: 0;
  left: 0;
  background-color: $color-white;

  @include bp(screen2) {
    position: absolute;
    left: auto;
    width: 22em;
  }

}

.c-search1__label {
  @extend %u-hide;
}

.c-search1__field {
  @extend %u-interactive-element-size;
  flex: 1 0 auto;
  padding: 0 0 0 2em;
  border: 1px solid $color-light-gray;
  border-radius: 3px;
  background: inline('../images/icon_magnify.svg') no-repeat left 0.5em center / 1em;

  @include bp(screen2) {
    padding: 0 0 0 $spacing-sm;
    background: none;
  }

}

.c-search1__submit-button {
  @extend %u-hide;
  @extend %o-button__9;
  background-image: inline('../images/icon_magnify.svg');

  @include bp(screen2) {
    position: static;
    left: auto;
  }

}

.c-search1__search-close-button {
  @extend %o-button__9;
  background-image: inline('../images/icon_close.svg');

  @include bp(screen2) {
    display: none;
  }

}
