// ##### Journal Grid Component ##### //

.c-journalgrid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 400px;
  margin: 0 $spacing-sm;
  padding: 1px;
  background-color: $color-light-gray;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);

  @supports (display: grid) {
    display: grid;
    align-items: stretch;
    max-width: none;
  }

  > a {
    width: calc(33% - 1px);
    margin: 0 1px 1px 0;

    @supports (display: grid) {
      width: auto;
      margin: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

}

.c-journalgrid__all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  background-color: $color-white;

  @supports (display: grid) {
    width: auto;
  }

  a {
    @extend %o-textlink__secondary;
    padding: $spacing-sm;
    text-align: center;
  }

}
