// ##### Media Feature Objects ##### //

%o-mediafeature {
  display: flex;
  flex-direction: column;

  @include u-heading() {
    @extend %o-heading2;
    order: 2;
  }

}

%o-mediafeature__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: 1;
  margin-bottom: $spacing-md;
  background-color: $color-light-gray;
}

.o-mediafeature__description {
  margin-bottom: $spacing-sm;
  order: 3;
}

.o-mediafeature__doi {
  @extend %o-textlink__secondary;
  order: 4;
}

.o-mediafeature--audio {
  @extend %o-mediafeature;

  .o-mediafeature__item {
    @extend %o-mediafeature__item;
    padding: $spacing-sm;
  }

}

.o-mediafeature--data {
  @extend %o-mediafeature;

  .o-mediafeature__item {
    @extend %o-mediafeature__item;
    padding: $spacing-sm;

    strong {
      margin-bottom: $spacing-md;
      text-transform: uppercase;
    }

    a {
      @extend %o-textlink__left-icon;
      background-image: inline('../images/icon_download-teal.svg');
    }

  }

}

.o-mediafeature--image {
  @extend %o-mediafeature;

  .o-mediafeature__item {
    @extend %o-mediafeature__item;

    img {
      max-width: 100%;
    }

  }

}

.o-mediafeature--video {
  @extend %o-mediafeature;

  .o-mediafeature__item {
    @extend %o-mediafeature__item;

    video {
      max-width: 100%;
    }

  }

}
