// ##### Subheader Component ##### //

.c-subheader {
  @extend %u-page-bleed;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 5px $spacing-sm;
  position: relative; // to absolute positioned nav component
  padding: $spacing-sm;
  border-top: 2px solid $color-light-gray;
  background-color: $color-white;

  @include bp(screen4) {
    padding: $spacing-sm $spacing-lg;
  }

  &.is-black {
    color: $color-black;
  }

  &.is-white {
    color: $color-white;
  }
}

.c-subheader__title {
  @extend %o-textlink__black;
  align-self: center;

  h1 {
    margin: 0;
    font-size: 1em;

    @include bp(screen3) {
      font-size: 1.2em;
    }
  }

  @include bp(screen3) {

    .has-banner & {
      grid-column: 2;
    }
  }

  .is-wide & {

    @include bp(screen3) {
      // hide offscreen:
      position: absolute;
      left: -9999px;
    }
  }

  .has-campus-label & {
    align-self: end;
  }
}

.c-subheader__banner {
  display: none;

  @include bp(screen3) {
    display: block;
    grid-column: 1;
    grid-row: 1;

    .has-campus-label & {
      grid-row: 1 / 3;
    }

    .is-wide.has-campus-label & {
      grid-row: 1;
    }

    img {
      display: block;
      height: 80px;

      .is-wide.has-campus-label & {
        height: 55px;
      }
    }
  }
}

.c-subheader__campus {
  @extend %o-textlink__black;
  grid-column: 1;
  grid-row: 2;

  @include bp(screen3) {

    .has-banner & {
      grid-column: 2;
    }

    .is-wide & {
      grid-column: 1;
    }
  }
}

.c-subheader__buttons {
  display: flex;
  flex-direction: column;
  grid-column: 3;

  .has-campus-label & {
    grid-row: 1 / 3;
  }

  button {
    @extend %o-button;
    border: 1px solid currentColor;
    box-shadow: $box-shadow2;
    color: currentColor;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }
}

.c-subheader__button-fragment {
  position: absolute;
  left: -9999px;

  @include bp(screen3) {
    display: block;
    position: static;
    left: auto;
  }
}
