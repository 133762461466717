// ##### Tab Content Components ##### //

/* in HTML but not styled:
.c-tabcontent {
}
*/

.c-tabcontent__main-heading {
  @extend %o-heading1a;

  &:focus {
    outline: $color-light-gray solid 1px;
  }

}

.c-tabcontent__divide2x {

  @include bp(screen3) {
    display: flex;
  }

}

.c-tabcontent__divide2x-child {

  @include bp(screen3) {
    width: 50%;

    &:first-child {
      padding-right: calc($spacing-md / 2);
    }

    &:last-child {
      padding-left: calc($spacing-md / 2);
    }

  }

}

.c-tabcontent-hide {
  display: none;
  // .c-tabcontent-reveal acts as opposite
}
