// ##### Publication Preview Component ##### //

.c-pubpreview {

  @include bp(screen1) {
    display: flex;
    align-items: flex-start;
  }

  + .c-pubpreview {
    border-top: 1px solid $color-dark-gray;

    @include bp(screen1) {
      padding-top: $spacing-md;
    }

  }

}

.c-pubpreview__img {
  display: none;

  @include bp(screen1) {
    display: inline-block;
    flex: 0 0 121px;
    margin-right: $spacing-md;
    margin-bottom: $spacing-md;
    border: 1px solid $color-light-gray;
    box-shadow: $box-shadow2;
  }

  img {
    display: block;
  }

}
