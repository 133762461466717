// ##### Global Variables ##### //

// ***** Colors ***** //

$color-orange: rgb(230, 87, 34);
$color-dark-orange: rgb(197, 69, 22);
$color-teal: rgb(18, 113, 129);
$color-light-teal: rgb(232, 241, 241);
$color-dark-blue: rgb(0, 85, 129);
$color-light-gray: rgb(198, 198, 198);
$color-dark-gray: rgb(88, 86, 88);
$color-light-black: rgb(51, 51, 51);
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-black-semitrans: rgba(124, 126, 127, 0.5);

// ***** Spacing ***** //

$spacing-base: 5px;
$spacing-sm: 10px;
$spacing-md: 20px;
$spacing-lg: 30px;
$spacing-xl: 50px;
$spacing-body: $spacing-sm;

// ***** General ***** //

$box-shadow1: 5px 5px 15px rgba(0, 0, 0, 0.3);
$box-shadow2: 2px 2px 2px rgba(0, 0, 0, 0.3);
$column-width: 28%;
$element-height: 32px;
$pointer-size-coarse: 2.8rem;
$pointer-size-fine: 2rem;
$thumbnail-width: 150px;
$line-height1: 1.3;
$line-height2: 1.3;
$round-corner: 3px;
$campuscarouselframe-spacing: 2.5rem;

// ***** Element Highlighting for Testing ***** //

// Ex: border: $bred;

$bred: 1px solid #f00;
$bblue: 1px solid #00f;
$bgreen: 1px solid #080;

// ##### Remote Path for Static Media Samples ##### //

$media-path: 'https://submit.escholarship.org/jschol_static/images/';
