// ##### Unit List Component ##### //

.c-unitlist {

  @include u-heading() {
    @extend %o-heading1a;
  }

  ul {
    @extend %u-reset-list-styles;
  }

  li {

    &:not(:last-child) {
      margin-bottom: $spacing-md;
    }

  }

  a {
    @extend %o-heading2;
    @extend %o-textlink__secondary;
  }

  + .c-unitlist {
    padding-top: $spacing-md;
    border-top: 1px solid $color-dark-gray;
  }

}

div[class='c-unitlist'] {
  
  &:not(:last-child) {
    padding-bottom: $spacing-md;
  }

}
