// ##### Footer Component ##### //

$footer-logo-width: 200px;

.c-footer {
  @extend %u-page-bleed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-md;
  background-color: $color-light-black;

  @include bp(screen2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}

.c-footer__nav {
  margin-bottom: $spacing-sm;

  ul {
    @extend %u-reset-list-styles;
    columns: 2;
    column-gap: $spacing-lg;

    li {
      margin-bottom: $spacing-md;
      break-inside: avoid;

      a {
        display: block;
        color: $color-white;
        text-decoration: none;
      }

    }

  }

  @include bp(screen2) {
    margin-bottom: 0;
    grid-column: 2;
    grid-row: 1 / 4;
  }

}

.c-footer__logo {
  margin-bottom: $spacing-md;
  color: $color-white;

  img {
    width: $footer-logo-width;
    height: 100%; // for IE 11
  }

}

.c-footer__icons {
  display: flex;
  justify-content: space-around;
  width: $footer-logo-width;
  height: auto;
  margin-bottom: $spacing-md;

  @supports (display: grid) {
    justify-content: space-evenly;
  }

  @include bp(screen2) {
    justify-self: stretch;
    grid-column: 1;
  }

  a {

    img {
      @extend %u-interactive-element-size;
    }

  }

}

.c-footer__copyright {
  color: $color-light-gray;
  font-size: 0.8em;
  line-height: $line-height1;

  @include bp(screen2) {
    grid-column: 1;
  }

  a {
    color: $color-white;
    text-decoration: none;
  }

}
