// ##### Publication Year Facet Component ##### //

.c-pubyear {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: $spacing-sm;
}

.c-pubyear__field {

  label {
    display: block;
  }

  input {
    @extend %u-interactive-element-size;
    width: 3.5em;
  }

}

.c-pubyear__button {
  @extend %o-button__8;
}
