// ##### Header 1 & 2 Component Styles ##### //

.c-header {
  @extend %u-page-bleed;
  display: flex;
  justify-content: space-between;
  margin-top: (-$spacing-sm);
  padding: $spacing-sm;
  background: $color-white;

  @include bp(screen4) {
    padding: $spacing-sm $spacing-lg;
  }

}

// Header 1 component logo:

.c-header__logo1 {

  img {
    @extend %u-interactive-element-size;
  }
}

// Header 2 component logo and tagline:

.c-header__logo2 {

  @include bp(screen3) {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  img {
    @extend %u-interactive-element-size;
  }

}

.c-header__logo2-tagline {
  display: none;

  @include bp(screen3) {
    display: block;
    margin-left: $spacing-sm;
    padding: 0.2em 0 0.2em $spacing-sm;
    border-left: 1px solid $color-dark-gray;
    color: $color-light-black;
    font-size: 0.8em;
  }

}

%c-header__search {
  position: absolute;
  right: $spacing-sm;
  left: $spacing-sm;
  transform: translateX(calc(100% + #{$spacing-sm}));
  transition: transform 0.5s;
  background: $color-white;
  z-index: 1; // allows .c-search2__refine to open over other page elements

  @include bp(screen3) {
    position: relative;
    margin: 0 $spacing-sm 0 0;
    transform: translateX(0%);
    overflow: visible !important;
  }

}

.c-header__search {
  @extend %c-header__search;
  overflow: hidden;
}

.c-header__search--active {
  @extend %c-header__search;
  transform: translateX(0%);
}

.c-header__search-open-button {
  @extend %o-button__9;
  align-self: flex-start;
  background-image: inline('../images/icon_magnify.svg');

  @include bp(screen3) {
    display: none;
  }

}
