// ##### Campus Search Component ##### //

$min-type-width: $screen1;
$max-type-width: $screen4;
$min-type-font: 18px;
$max-type-font: 26px;

.c-campussearch {
  margin-bottom: $spacing-md;
  padding: $spacing-sm;
  background-color: $color-light-teal;
  box-shadow: $box-shadow1;

  @include bp(screen2) {
    padding: $spacing-md 20%;
  }

}

.c-campussearch__label {
  @include u-fluid-type($min-type-width, $max-type-width, $min-type-font, $max-type-font);
  display: block;
  margin-right: 2.3rem;
  margin-bottom: $spacing-sm;
  color: $color-orange;
  text-align: center;
}

.c-campussearch__search {
  display: flex;

  input[type='search'] {
    @extend %u-interactive-element-size;
    flex: 1 1 auto;
    padding: 0 $spacing-sm;
    border: 1px solid $color-light-gray;
    font-size: 1.2em;
  }

  button {
    @extend %o-button__9;
    margin-left: $spacing-base;
    background-image: inline('../images/icon_magnify-teal.svg');
  }

}
