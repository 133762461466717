// ##### adminbar Component ##### //

.c-issueTable {
  width: 100%;
  margin-bottom: 10px;
  th {
    font-weight: bold;
  }
  tr, th, td {
    border: 1px solid black;
    text-align: left;
    padding: 5px;
  }
  label {
    margin-right: 15px;
  }
  input[type=radio] {
    margin-right: 5px;
  }
  input[type=text] {
    width: 100%;
  }
}
