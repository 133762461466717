// ##### Widget Selector Component ##### //

$widget-selector-right-margin: 30px;

.c-widgetselector {
  display: inline-flex;
  align-items: center;
  margin-right: 2px;
  position: relative;
  align-items: flex-end;
  min-height: $element-height;
  margin-right: $widget-selector-right-margin + $spacing-md;
}

.c-widgetselector__heading {
  margin: 0 $spacing-sm 0 0;
  text-align: right;
  width: 7.5em; // component width
  font-size: 1.5em;

  a {
    @extend %o-textlink__black;
  }

}

.c-widgetselector__selector {

  summary {
    @extend %u-interactive-element-size;
    position: relative;

    &::after {
      content: '+';
      padding: 0.2em;
      font-size: 1.8em;
    }

  }

  &[open] {

    summary::after {
      content: "\2013";
    }

  }

}

.c-widgetselector__menu {
  position: absolute; // to .c-subheader
  top: 31px;
  right: 0;
  width: 150px;
  text-transform: none;
  background-color: $color-white;
  z-index: 2;
}

.c-widgetselector__sub-heading {
  @extend %nav-item;
  background-color: $color-light-gray;
  color: $color-black;
}

.c-widgetselector__items {

  a {
    @extend %nav-item;
    border-top: none;
  }

}
