.c-drawer {
  width: 22%;
  min-width: 350px;
  background-color: lighten($color-light-gray, 15%);
}

.c-drawer__heading {
  padding: $spacing-sm;
  background-color: $color-dark-gray;
  color: $color-white;
  text-transform: uppercase;
  position: relative;
}

.c-drawer__row {
  padding: 15px 20px 15px 45px;
}

.c-drawer__list-item, .c-drawer__list-item-subnav {
  @extend .c-drawer__row;
  border-bottom: 1px solid $color-black;
  
  background-image: url("../images/th-list.svg");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 20px 15px;

  a {
    color: $color-dark-blue;
    text-decoration: none;
  }
}

.c-drawer__list-item-subnav {
  padding: 15px 20px 0 45px;
}

.c-drawer__list-hidden-label {
  display: none;
}

.c-drawer__list-label {
  display: block;
  font-weight: 700;
}

.c-drawer__list-item-text-input {
  width: calc(100% - 24px);
  margin-bottom: 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  line-height: 1.4;
  
  &.nav-element {
    margin-top: -8px;
  }
}

.c-drawer__list-item-radio-input {
  display: block;

  input[type=radio] {
    margin: 0 4px 0 0;
  }
}

.c-drawer__list-item-subnav .c-drawer__list-item {
  margin-right: -20px;
  border-top: 1px solid $color-black;
  border-bottom: 0;
  
  input {
    margin: 5px;
  }
}

.c-drawer__add-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $color-dark-gray;
  color: $color-white;
  display: flex;
}

.c-drawer__add-item {
  flex: 1 1 0;
  border-right: 1px solid black;
  padding: $spacing-sm 20px $spacing-sm $spacing-sm;
  margin: $spacing-sm 0 $spacing-sm $spacing-sm;
  
  button {
    padding-right: $spacing-sm;
    padding-left: $spacing-sm;
    border: none;
    background-color: inherit;
    width: 100%;
    
    img {
      width: 20px;
      margin-top: -2px;
    }
  }
}

.c-drawer__add-folder {
  flex: 1 1 0;
  padding: $spacing-sm;
  margin: $spacing-sm;

  button {
    padding-right: $spacing-sm;
    padding-left: $spacing-sm;
    border: none;
    background-color: inherit;
    width: 100%;
    
    img {
      width: 20px;
      margin-top: -2px;
    }
  }
}

.c-drawer__list-item-subnav-header {
  margin-bottom: 15px;
}

//.SortableListItem-dragging {
//  padding: 5px;
//  margin: 0;
//  font-size: 0;
//}

.c-drawer__nav-buttons {
  position: absolute;
  right: 0;
  top: 0;
  
  button {
    padding-right: $spacing-sm;
    padding-left: $spacing-sm;
    border: none;
    background-color: inherit;
    
    img {
      width: 20px;
      margin-top: -2px;
    }
  }
}

.editable-outline {
  border: 1px solid red;
  &:hover {
    border: 1px solid blue;
  };
}












.c-edit__nav {
  flex: 0 1 auto; // to .c-subheader flexbox
}

.c-edit__nav-sub {
  position: relative;
  
  &[open] {

    .c-edit__nav-button::after {
      transform: rotate(180deg);
    }

  }
  
}

.c-edit__nav-button {
  padding-right: 25px;
  
  img {
    width: 25px;
  }
  
  &::after {
    margin-left: 10px;
    content: '\00bb'; // double-right arrow

    @include bp(screen3) {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 7px;
      background: url('../images/icon_arrow-down.svg') no-repeat center / 13px;
      content: '';
    }

  }

}

.c-edit__nav-items {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateX(103%); // moves sub-items to right side of main-items

  @include bp(screen3) {
    top: auto;
    width: 200px;
    max-height: 340px;
    transform: translateX(0);
    overflow: auto;
  }

  a {
    @extend %c-nav__item;
  }
}

.c-editable__modal {
  h1 {
    margin: 0 -21px 0.67em -21px;
    padding: 5px 20px;
    font-size: 1.5em;
    background-color: $color-teal;
    color: white;
  }
}

.c-drawer__working-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-white, 0.5);
  overflow: auto;
  z-index: 99; // stack above all components

  @supports (backdrop-filter: blur()) {
    background-color: rgba($color-white, 0.5);
    backdrop-filter: blur(5px);
  }
}
