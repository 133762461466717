// ##### adminbar Component ##### //

.c-editable-h3 {
  display: inline-block;
}

.c-editable__working {
  position: absolute; /* Stay in place */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: $color-black-semitrans; /* Grey w/ opacity */
  z-index: 1; /* Sit on top of content */
}

.c-editable__working-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  color: $color-white;
  font-size: 200%;
}

.c-editable__edit-buttons {
   position: absolute;
   right: 1em;
   bottom: 1em;
}

.c-editable__delete-button {
  margin-left: 0.5em;
}

.c-editable-page__label {
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
}

.c-editable-page__radio-label {
  font-weight: 700;
  margin-right: 1em;
}

.c-editable-page__radio {
  margin-right: 5px;
}

.c-editable-page__radio-vertical {
  margin-right: 5px;
  float: left;
}

.c-editable-page__input {
  margin-bottom: 20px;
  padding: 6px 12px;
  line-height: 1.4;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.c-editable-page__button {
  margin-right: 10px;
}

.c-editable-page__button:disabled {
  color: graytext
}

.c-editable-floatright {
  float: right; 
}

.c-editable-help__icon {
  margin-top: 8px;
  margin-left: 20px;
}

.c-editable-tableCell {
  text-align: left !important;
}

.trumbowyg-box, .trumbowyg-editor {
  min-height: 300px;
}

// ### Toggle button used on UnitcarouselConfigComp ### //
// ## Courtesy https://codepen.io/personable/pen/stpwD

@mixin can-toggle-branding(
  $can-toggle-off-color: #777, 
  $can-toggle-on-color: #127181,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
)
{
  
  input[type="checkbox"] {
    
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }
    
    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after { color: darken($can-toggle-off-color, 10%); }
      }
    }
    &:hover ~label { color: darken($can-toggle-off-color, 5%); }
    &:checked {
      ~ label {
        &:hover { color: darken($can-toggle-on-color, 3%); }
        
        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after { color: darken($can-toggle-on-color, 5%); }
        }
      }
      
      &:focus, &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after { color: darken($can-toggle-on-color, 10%); }
          }
        } 
      }
    }
  }
  
  label {
    
    .can-toggle__label-text {
      margin: 0 10px 10px 0;
      display: inline-block;
    }
    
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before { color: $can-toggle-inactive-text; }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color; 
      }
    }
    
  }
}

@mixin can-toggle-appearance 
( 
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 12px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
)
{
  $can-toggle-switch-width: calc($can-toggle-width/2);
  
  input[type="checkbox"] {

    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        &:after { box-shadow: $can-toggle-shadow; } 
      }
    }
    
    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }  
        }
      }
      &:focus, &:hover {
        ~ label {
          .can-toggle__switch { &:after { box-shadow: $can-toggle-shadow; } }
        } 
      }
    }
  }
  
  label {
    font-size: $can-toggle-label-font-size;
    
    .can-toggle__switch {
      height: $can-toggle-height;
      display: inline-block;
      width:  $can-toggle-width;
      border-radius: $can-toggle-border-radius;
      
      &:before {
        left: calc($can-toggle-width/2);
        font-size: $can-toggle-switch-font-size; 
        line-height: $can-toggle-height;
        width: calc($can-toggle-width/2);
        padding: 0 12px;
      }
      
      &:after {
        top: $can-toggle-offset; left: $can-toggle-offset;
        border-radius: calc($can-toggle-border-radius/2);
        width: $can-toggle-switch-width - $can-toggle-offset; 
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }
      
      &:hover {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    } 
  }
}



.can-toggle {
  position: relative;
  *, *:before, *:after { box-sizing: border-box; }
  //overflow: hidden;
  
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;
    
    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch { opacity: 0.4; }
    }
    
    &:checked {
      ~ label {
        
        .can-toggle__switch {
          
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }
        
          &:after {
            content: attr(data-checked);
          }  
        }
      }
      
      &:focus, &:hover {
        ~ label {
        } 
      }
    }
  }
  
  label {
    user-select: none;
    position: relative;
    align-items: center;
    
    .can-toggle__label-text { 
      display: inline-block;
      margin: 0 10px 10px 0;
      padding-left: 32px;
    }
    
    .can-toggle__switch {
      position: relative;
      
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }
      
      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0,0,0);
      }

    }
    
  }
  
  // Default values for .can-toggle class
  @include can-toggle-branding; 
  @include can-toggle-appearance; 
  
  // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
  &.can-toggle--size-small {
    @include can-toggle-appearance
    (
      160px, // Toggle width
      28px,  // Toggle height
      2px,   // Toggle border radius
      1px,   // Offset (distance btw switch and box)
      16px,  // Label font size
      10px,  // Switch font size
      0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
    ); 
  }
  
}

/* Pseudo-table: a thing that formats like a table, but isn't for tabular data (e.g. lining up controls) */
.c-editable-pTable {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.c-editable-pRow {
  display: table-row;
}

.c-editable-pCell {
  display: table-cell;
  padding-left: 0.5em;
  margin-left: 0.5em;
}
