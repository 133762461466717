// ##### Journal Information Component ##### //

.c-journalinfo {

  img {
    margin: 0 $spacing-md $spacing-md 0;
  }

  ul {
    @extend %u-reset-list-styles;

    li {
      font-size: 1.1em;
    }

  }

  a {
    @include u-overflow-wrap();
  }

}
