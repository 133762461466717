// ##### Divide Content Objects ##### //

%o-dividecontent {
  @extend %u-responsive-images;

  @include bp(screen1) {
    column-gap: $spacing-lg;
  }

}

.o-dividecontent2x {
  @extend %o-dividecontent;

  @include bp(screen1) {
    columns: 2;
  }

}

.o-dividecontent2x--ruled {
  @extend %o-dividecontent;

  @include bp(screen1) {
    columns: 2;
    column-rule: 1px solid $color-light-gray;
  }

}
