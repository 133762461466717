// ##### Item Actions Component ##### //

.c-itemactions {
  position: relative; // for o-download and c-share
  margin-bottom: $spacing-md;

  @include bp(screen3) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: $spacing-sm;
  }

  > * {
    margin-bottom: $spacing-sm;

    &:last-child {
      margin-left: auto; // right-justify last button
    }

    &:not(:last-child) {
      margin-right: $spacing-sm;
    }

  }

}

// Specific itemaction button styles appearing in various layouts:

.c-itemactions__button-print {
  @extend %o-button__6;
  background-image: inline('../images/icon_print-white.svg');
}

.c-itemactions__button-buy {
  @extend %o-button__6;
  background-image: inline('../images/icon_shopping-cart-white.svg');
}

.c-itemactions__link-buy {
  @extend %o-textlink__left-icon;
  @extend %o-textlink__secondary;
  background-image: inline('../images/icon_shopping-cart-teal.svg');
}
