// ##### Text List Objects ##### //

%o-textlist {
  margin-bottom: $spacing-sm;

  li {

    &:not(:last-child) {
      margin-bottom: $spacing-sm;
    }

    a {
      @extend %o-textlink__secondary;
    }

  }

}

// ***** Bulleted Text List ***** //

.o-textlist1 {
  @extend %o-textlist;
  padding-left: $spacing-lg;
}

// ***** Non-bulleted Text List ***** //

.o-textlist2 {
  @extend %o-textlist;
  @extend %u-reset-list-styles;
}
