// ##### Statistics Objects ##### //

.o-stat { // This parent element not used within carousels
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
}

%o-stat__number {
  @extend %u-sourcesansprolight-font-stack;
  display: block;
  margin-bottom: $spacing-base;
  font-size: 1.3em;
  font-weight: bold;

  @include bp(screen3) {
    font-size: 1.6em;
  }

}

%o-stat__type {
  display: inline-block;
  padding: 2.2em $spacing-base 0;
  background: no-repeat top center / auto 1.5em;
  font-size: 0.9em;
  text-align: center;

  @include bp(screen3) {
    font-size: 1em;
  }

  a {
    @extend %o-stat__number;
    @extend %o-textlink__secondary;
  }

  b {
    @extend %o-stat__number;
    color: $color-light-black;
  }

}

.o-stat--item {
  @extend %o-stat__type;
  background-image: inline('../images/icon_book-black.svg');
}

.o-stat--view {
  @extend %o-stat__type;
  background-image: inline('../images/icon_eye-black.svg');
}

.o-stat--passed {
  @extend %o-stat__type;
  background-image: inline('../images/icon_open-access-black.svg');
}

.o-stat--journals { // magazine icon
  @extend %o-stat__type;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgZmlsbD0iIzAwMDAwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgODQgODUiIHg9IjBweCIgeT0iMHB4Ij48cGF0aCBkPSJtNDAxLjAzIDYyMS4zYy0xLjc0LS4wOTQtMy4wMyAxLjEzOC0zLjAzIDMuMDU5djY3LjM4YzAgMi4xOTUgMS43MDIgNC40MTQgMy44MTMgNC45NzRsMzYuMTkgOS41Nzh2LTMuOTU1LTY3LjM1LTMuOTU1bC0zNi4xOS05LjYwOWMtLjI2NC0uMDctLjUzMy0uMTEtLjc4MS0uMTI0bTc3LjkzOCAwYy0uMjQ5LjAxMy0uNTE3LjA1My0uNzgxLjEyNGwtMzYuMTkgOS42MDl2My45NTUgNjcuMzUgMy45NTVsMzYuMTktOS41NzhjMi4xMTEtLjU2IDMuODEzLTIuNzc5IDMuODEzLTQuOTc0di02Ny4zOGMwLTEuOTIxLTEuMjkyLTMuMTUyLTMuMDMtMy4wNTltLTcyLjk2OSAxMy4xbDI0IDYuMzk1djE5LjgwNGwtMjQtNi4zNjV2LTE5LjgzNW02OCAwdjMuOTg2bC0yNCA2LjM2NXYtMy45NTVsMjQtNi4zOTVtMCAxNS44NXYzLjk4NmwtMjQgNi4zNjV2LTMuOTU1bDI0LTYuMzk2bS02OCAxNS44NWwyNCA2LjM5NnYzLjk1NWwtMjQtNi4zNjV2LTMuOTg2bTY4IDB2My45ODZsLTI0IDYuMzY1di0zLjk1NWwyNC02LjM5Nm0tNjggMTUuODgxbDI0IDYuMzY1djMuOTU1bC0yNC02LjM2NXYtMy45NTVtNjggMHYzLjk1NWwtMjQgNi4zNjV2LTMuOTU1bDI0LTYuMzY1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzk4LTYyMS4yOSkiLz48L3N2Zz4=');
}

.o-stat--units {
  @extend %o-stat__type;
  background-image: inline('../images/icon_university-black.svg');
}

.o-stat--articles {
  @extend %o-stat__type;
  background-image: inline('../images/icon_file-text-black.svg');
}

.o-stat--books {
  @extend %o-stat__type;
  background-image: inline('../images/icon_book-black.svg');
}

.o-stat--theses {
  @extend %o-stat__type;
  background-image: inline('../images/icon_graduation-cap-black.svg');
}
