// ##### Styles for eScholarship ##### //

// *** Third-party Styles *** //

@import '../node_modules/flickity/dist/flickity.min.css';
@import '../node_modules/trumbowyg/dist/ui/trumbowyg.min.css';
@import '../node_modules/trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css';
@import '../node_modules/react-sortable-tree/style.css';
@import '../node_modules/react-datetime/css/react-datetime.css';

// ***** Global Settings ***** //

@import 'breakpoints';
@import 'variables';
@import 'utilities';

// ***** Added to jschol (not in eschol-ui) ***** //
@import 'adminbar';
@import 'drawer';
@import 'editable';
@import 'eschol_pdf_viewer';
@import 'issueConfig';
@import 'redirectConfig';
@import 'statsReports';
@import 'widgetselector';

// ***** Objects ***** //

@import 'alert';
@import 'button';
@import 'columnbox';
@import 'customselector';
@import 'dividecontent';
@import 'heading';
@import 'input';
@import 'stat';
@import 'textlink';
@import 'well';

// ***** Components ***** //

@import 'authorlist';
@import 'backgroundcolorpicker';
@import 'breadcrumb';
@import 'campuscarouselframe';
@import 'campushero';
@import 'campussearch';
@import 'checkbox';
@import 'checkcontrast';
@import 'clientmarkup';
@import 'columns';
@import 'datatable';
@import 'daterange';
@import 'descriptionlist';
@import 'download';
@import 'elementcolorpicker';
@import 'export';
@import 'facetbox';
@import 'filter';
@import 'footer';
@import 'header';
@import 'hero';
@import 'homesection';
@import 'infopages';
@import 'issue';
@import 'itemactions';
@import 'itemunavailable';
@import 'journal';
@import 'journalcarousel';
@import 'journalgrid';
@import 'journalinfo';
@import 'jump';
@import 'language';
@import 'lazyimage';
@import 'marquee';
@import 'mediafeature';
@import 'mediafile';
@import 'mediafilegrid';
@import 'medialist';
@import 'mediaviewer';
@import 'modal';
@import 'nav';
@import 'navbar';
@import 'pagination';
@import 'pdfview';
@import 'pub';
@import 'pubdata';
@import 'pubinfo';
@import 'publocation';
@import 'pubpreview';
@import 'pubyear';
@import 'refine';
@import 'relateditems';
@import 'scholworks';
@import 'search1';
@import 'search2';
@import 'servererror';
@import 'share';
@import 'skipnav';
@import 'sort';
@import 'sortpagination';
@import 'socialfeed';
@import 'socialicons';
@import 'statcarousel';
@import 'subheader';
@import 'subheadercontrols';
@import 'tabcontent';
@import 'tabs';
@import 'teaser';
@import 'testmessage';
@import 'textlist';
@import 'togglecontent';
@import 'togglelist';
@import 'togglesection';
@import 'toplink';
@import 'unitcarousel';
@import 'unitlist';
@import 'unitseries';
@import 'viewexternal';
@import 'withdrawmodal';
@import 'wizard';

// ***** Global Styles ***** //

@import 'fonts';
@import 'resets';
@import 'ui-library';

@import 'klaro';

body {
  margin: 0 auto;
  padding: $spacing-sm $spacing-sm 0;
  background-color: lighten($color-light-gray, 15%);
  font-family: 'SourceSansPro', 'Arial', 'Helvetica', sans-serif;
  overflow-x: hidden;

  @include bp (screen2) {
    padding: $spacing-sm $spacing-md 0;
  }

}

p {
  margin: 0 0 $spacing-md;
  line-height: $line-height1;
}
